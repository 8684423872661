//@ts-nocheck
import {
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import BaseContainer from "../../components/BaseContainer"
import { api, snackbarCatch } from "../../services/api"
import DataTable, { TableColumn } from "react-data-table-component"
import {
    AdminGetAdvertiserBudgetDto,
    AdminGetAdvertiserBudgetSortByEnum,
    AdminGetAdvertiserBudgetSortDirectionEnum,
} from "../../services/openapi"
import { AddRounded, DeleteRounded, ExpandMoreRounded } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { subWeeks } from "date-fns"
import DateRangePicker from "../../components/DateRangePicker"

const defaultParams: {
    page: number
    limit: number
    sortBy: AdminGetAdvertiserBudgetSortByEnum
    sortDirection: AdminGetAdvertiserBudgetSortDirectionEnum
} = {
    page: 1,
    limit: 10,
    sortBy: "created_at",
    sortDirection: "asc",
}

const AdScene = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = useState(defaultParams.page)
    const [limit, setLimit] = useState(defaultParams.limit)
    const [sorting, setSorting] = useState<{
        sortBy: AdminGetAdvertiserBudgetSortByEnum
        sortDirection: AdminGetAdvertiserBudgetSortDirectionEnum
    }>({
        sortBy: defaultParams.sortBy,
        sortDirection: defaultParams.sortDirection,
    })
    const [dateRange, setDateRange] = useState({
        startDate: subWeeks(new Date(), 1),
        endDate: new Date(),
    })

    const onSortChanged = (column: TableColumn<AdminGetAdvertiserBudgetDto>, direction: "asc" | "desc") => {
        setSorting({
            sortBy: column.sortField! as AdminGetAdvertiserBudgetSortByEnum,
            sortDirection: direction,
        })
    }

    // TODO: auction max/min/avg

    const advertisers = api.advertiser.useAdvertiserBudget({
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
        page,
        limit,
        ...sorting,
    })
    const advertiserStats = api.advertiser.useAdvertiserStats({
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
    })

    const columns = useMemo<TableColumn<AdminGetAdvertiserBudgetDto>[]>(
        () => [
            // {
            //     name: "Période",
            //     selector: (row) => "-",
            //     sortField: "",
            //     sortable: true,
            // },
            {
                name: "Nom de l'annonceur",
                selector: (row) => row.username,
                sortField: "username",
                sortable: true,
            },
            {
                name: "Budget pub",
                selector: (row) => row.ad_budget,
                sortField: "ad_budget",
                sortable: true,
            },
            {
                name: "Utilisation",
                selector: (row) => row.ad_usage,
                sortField: "ad_usage",
                sortable: true,
            },
            {
                name: "Solde restant",
                selector: (row) => row.ad_remaining_sold,
                sortField: "ad_remaining_sold",
                sortable: true,
            },
        ],
        [],
    )

    if (
        advertisers.isLoading ||
        advertisers.data === undefined ||
        advertiserStats.isLoading ||
        advertiserStats.data === undefined
    ) {
        return (
            <BaseContainer>
                <Typography variant="h3" component="h1">
                    Requêtes sur les recettes publicitaires
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <CircularProgress />
                </Box>
            </BaseContainer>
        )
    }

    return (
        <BaseContainer>
            <Typography variant="h3" component="h1">
                Requêtes sur les recettes publicitaires
            </Typography>

            <Box mt={3}>
                <DateRangePicker value={dateRange} onChange={setDateRange} />
            </Box>

            <Box mt={3} display={"flex"} justifyContent="space-between">
                <Box width="80%">
                    <DataTable
                        persistTableHead
                        // noDataComponent
                        columns={columns}
                        data={advertisers.data.data}
                        responsive
                        onSort={onSortChanged}
                        sortServer
                        noContextMenu
                        noHeader
                        expandOnRowDoubleClicked
                        selectableRowsHighlight
                        highlightOnHover
                        sortIcon={<ExpandMoreRounded />}
                        // onRowClicked
                        pagination
                        paginationServer
                        paginationPerPage={advertisers.data.limit}
                        paginationTotalRows={advertisers.data.totalCount}
                        paginationRowsPerPageOptions={[5, 10, 20]}
                        paginationDefaultPage={defaultParams.page}
                        defaultSortAsc={defaultParams.sortDirection === "asc"}
                        defaultSortFieldId={defaultParams.sortBy}
                        onChangeRowsPerPage={setLimit}
                        onChangePage={setPage}
                        // fixedHeader
                        // fixedHeaderScrollHeight
                        keyField="code"
                    />
                </Box>

                <TableContainer component={Paper} style={{ width: "17%" }}>
                    <Table aria-label="gender">
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">plus basse enchère</TableCell>
                                <TableCell align="center">{advertiserStats.data.min_bid}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell align="center">enchère moyenne</TableCell>
                                <TableCell align="center">{advertiserStats.data.bid_avg}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell align="center">plus haute enchère</TableCell>
                                <TableCell align="center">{advertiserStats.data.max_bid}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </BaseContainer>
    )
}
export default AdScene
