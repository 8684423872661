/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { AdResponse } from "../models"

export interface ClickedRequest {
    id: string
}

export interface SeenRequest {
    id: string
}

/**
 *
 */
export class AdApi /* extends runtime.BaseAPI */ {
    /**
     */
    static clicked({ id }: ClickedRequest): Promise<void> {
        return rest.post(`/ad/clicked/${encodeURIComponent(id)}`, undefined, true)
    }

    /**
     */
    static getAnAd(): Promise<AdResponse> {
        return rest.get(`/ad`)
    }

    /**
     */
    static getRandomAd(): Promise<AdResponse> {
        return rest.get(`/ad/random`)
    }

    /**
     */
    static seen({ id }: SeenRequest): Promise<void> {
        return rest.post(`/ad/seen/${encodeURIComponent(id)}`, undefined, true)
    }
}
