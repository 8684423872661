/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
    ConfirmEmailDto,
    CreateAdvertiserUserDto,
    CreateMooverUserDto,
    CreateOwnerUserDto,
    ForgotPasswordDto,
    GetUserDto,
    IsEmailAvailableDto,
    LoginResponseDto,
    LoginUserDto,
    RefreshTokenDto,
    ResetPasswordWithTokenDto,
    UpdateCredentialsDto,
} from "../models"

export interface ConfirmEmailRequest {
    confirmEmailDto: ConfirmEmailDto
}

export interface ForgotPasswordRequest {
    forgotPasswordDto: ForgotPasswordDto
}

export interface IsEmailAvailableRequest {
    email: string
}

export interface LoggedForgotPasswordRequest {
    token: string
    updateCredentialsDto: UpdateCredentialsDto
}

export interface LoginRequest {
    loginUserDto: LoginUserDto
}

export interface RefreshTokenRequest {
    refreshTokenDto: RefreshTokenDto
}

export interface RegisterAdvertiserRequest {
    createAdvertiserUserDto: CreateAdvertiserUserDto
}

export interface RegisterAmbassadorRequest {
    createMooverUserDto: CreateMooverUserDto
}

export interface RegisterMooverRequest {
    createMooverUserDto: CreateMooverUserDto
}

export interface RegisterOwnerRequest {
    createOwnerUserDto: CreateOwnerUserDto
}

export interface ResetForgottenPasswordRequest {
    resetPasswordWithTokenDto: ResetPasswordWithTokenDto
}

/**
 *
 */
export class UsersApi /* extends runtime.BaseAPI */ {
    /**
     * Confirm email with token
     */
    static confirmEmail({ confirmEmailDto }: ConfirmEmailRequest): Promise<void> {
        return rest.post(`/users/confirm-email`, confirmEmailDto, true)
    }

    /**
     * Delete user
     */
    static deleteUser(): Promise<void> {
        return rest.delete(`/users`, undefined)
    }

    /**
     * Send email to reset password
     */
    static forgotPassword({ forgotPasswordDto }: ForgotPasswordRequest): Promise<void> {
        return rest.post(`/users/forgot-password`, forgotPasswordDto, true)
    }

    /**
     * Get the user\'s data
     */
    static getUser(): Promise<GetUserDto> {
        return rest.get(`/users`)
    }

    /**
     * Check if an email address is already used by another account
     */
    static isEmailAvailable({ email }: IsEmailAvailableRequest): Promise<IsEmailAvailableDto> {
        return rest.get(`/users/email-available/${encodeURIComponent(email)}`)
    }

    /**
     * Send email to reset password when logged in
     */
    static loggedForgotPassword({ updateCredentialsDto, token }: LoggedForgotPasswordRequest): Promise<void> {
        return rest.put(`/users/credentials/${encodeURIComponent(token)}`, updateCredentialsDto, true)
    }

    /**
     * Log user in
     */
    static login({ loginUserDto }: LoginRequest): Promise<LoginResponseDto> {
        return rest.post(`/users/login`, loginUserDto)
    }

    /**
     * Delete user\'s session
     */
    static logout(): Promise<void> {
        return rest.post(`/users/logout`, undefined, true)
    }

    /**
     * Refresh a token
     */
    static refreshToken({ refreshTokenDto }: RefreshTokenRequest): Promise<LoginResponseDto> {
        return rest.post(`/users/refresh-token`, refreshTokenDto)
    }

    /**
     * Create a advertiser
     */
    static registerAdvertiser({ createAdvertiserUserDto }: RegisterAdvertiserRequest): Promise<void> {
        return rest.post(`/users/register/advertiser`, createAdvertiserUserDto, true)
    }

    /**
     * Create a new ambassador
     */
    static registerAmbassador({ createMooverUserDto }: RegisterAmbassadorRequest): Promise<void> {
        return rest.post(`/users/register/ambassador`, createMooverUserDto, true)
    }

    /**
     * Create a new moover
     */
    static registerMoover({ createMooverUserDto }: RegisterMooverRequest): Promise<void> {
        return rest.post(`/users/register/moover`, createMooverUserDto, true)
    }

    /**
     * Create a restaurant owner
     */
    static registerOwner({ createOwnerUserDto }: RegisterOwnerRequest): Promise<void> {
        return rest.post(`/users/register/owner`, createOwnerUserDto, true)
    }

    /**
     * Reset password with token verification
     */
    static resetForgottenPassword({ resetPasswordWithTokenDto }: ResetForgottenPasswordRequest): Promise<void> {
        return rest.post(`/users/forgotten-password/reset`, resetPasswordWithTokenDto, true)
    }

    /**
     * Send email to reset password when logged in
     */
    static send(): Promise<void> {
        return rest.post(`/users/credentials/mail`, undefined, true)
    }
}
