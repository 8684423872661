import { IconButton, Menu, MenuItem, Popover } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  AccountCircleRounded,
  CreditCard,
  CreditCardRounded,
  DashboardRounded,
  EuroRounded,
  FeaturedVideoRounded,
  MoneyRounded,
  PaymentRounded,
  PeopleRounded,
  RestaurantRounded,
} from "@material-ui/icons";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../../services/api";
import { GetUserDto } from "../../services/openapi";

import { urls } from "../../services/urls";
import { LinkIcon, MultiLinkIcon } from "../LinkIcon";

const useStyles = makeStyles((theme) =>
  createStyles({
    sectionDesktop: {
      display: "flex",
      height: "62px",
    },
    menuPaper: {
      marginTop: theme.spacing(1),
      border: `1px solid #7676a3`,
      backgroundColor: theme.palette.primary.main,
      color: "#DDD",
    },
  })
);

type Props = {
  user: GetUserDto;
};

const PrivateNavBar = ({ user }: Props): JSX.Element => {
  const classes = useStyles();
  const { search, ...location } = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutateAsync: logoutMutation } = api.auth.useLogout();
  const handleLogout = () => {
    handleClose();
    return logoutMutation();
  };

  return (
    <>
      <div className={classes.sectionDesktop}>
        <LinkIcon
          url={urls.users}
          title="Utilisateurs"
          Icon={DashboardRounded}
        />
        <MultiLinkIcon
          active={[
            urls.restaurant,
            urls.statsCreation,
            urls.statsClients,
            urls.statsSponsoring,
          ]?.includes(location.pathname)}
          title="Restaurants"
          Icon={RestaurantRounded}
          options={[
            {
              url: urls.statsCreation,
              name: "Stats création",
            },
            {
              url: urls.statsClients,
              name: "Stats Client",
            },
            {
              url: urls.statsSponsoring,
              name: "Stats Sponsoring",
            },
            {
              url: urls.statsJobOffers,
              name: "Stats Offres d'emploi",
            },
          ]}
        />
        <LinkIcon url={urls.moover} title="Mouver" Icon={PeopleRounded} />
        <LinkIcon
          url={urls.ambassador}
          title="Ambassadeur"
          Icon={PeopleRounded}
        />
        <LinkIcon url={urls.ad} title="Ad" Icon={FeaturedVideoRounded} />
        {/* <LinkIcon
          url={urls.sponsoring}
          title="Sponsoring"
          Icon={PaymentRounded}
        /> */}
        <MultiLinkIcon
          active={[
            urls.exchangeOrder.attributed,
            urls.exchangeOrder.moovers,
            urls.exchangeOrder.restaurants,
          ]?.includes(location.pathname)}
          title="Bon d'échange"
          Icon={EuroRounded}
          options={[
            {
              url: urls.exchangeOrder.attributed,
              name: "Suivi des capitaux",
            },
            {
              url: urls.exchangeOrder.moovers,
              name: "Moover",
            },
            {
              url: urls.exchangeOrder.restaurants,
              name: "Restaurant",
            },
            {
              url: urls.exchangeOrder.roue,
              name: "Super roue",
            },
          ]}
        />
        <LinkIcon url={urls.voucher} title="Bons cadeaux" Icon={MoneyRounded} />
      </div>
      <div>
        <IconButton
          size="medium"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <AccountCircleRounded />
        </IconButton>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          id="login-menu"
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{ paper: classes.menuPaper }}
        >
          <MenuItem disabled>{user.email}</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Popover>
      </div>
    </>
  );
};

export default PrivateNavBar;
