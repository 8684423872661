//@ts-nocheck
import React from "react"
import ReactDOM from "react-dom"
import LoginGate from "./LoginGate"
import { QueryClientProvider, MutationCache, QueryCache, QueryClient } from "react-query"
import { CssBaseline, NoSsr } from "@material-ui/core"
import { BrowserRouter } from "react-router-dom"
import { SnackbarProvider } from "notistack"
import DateFnsUtils from "@date-io/date-fns"
import { fr } from "date-fns/locale"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"

export const queryCache = new QueryCache()
export const mutationCache = new MutationCache()
export const queryClient = new QueryClient({
    queryCache,
    mutationCache,
    defaultOptions: {
        queries: {
            notifyOnChangeProps: ["data", "error", "isError", "isLoading"],
        },
    },
})

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline />
        <NoSsr>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <SnackbarProvider
                        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                        autoHideDuration={5000}
                    >
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                            <LoginGate />
                        </MuiPickersUtilsProvider>
                    </SnackbarProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </NoSsr>
    </React.StrictMode>,
    document.getElementById("root"),
)
