/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime";
import rest from "../../http";
import {
  GetMyRestaurantDto,
  SavingScannedQrCodeDataDto,
  ScanSavindQrCodeResponseDto,
  UpdateRestaurantOwnerProfileDto,
} from "../models";

export interface CreateJobOfferRequest {
  offer: Blob;
}

export interface ScanQrCodeRequest {
  savingScannedQrCodeDataDto: SavingScannedQrCodeDataDto;
}

export interface ScanRetroQrCodeRequest {
  savingScannedQrCodeDataDto: SavingScannedQrCodeDataDto;
}

export interface ServeJobOfferRequest {
  filename: string;
}

export interface ServeRestaurantPictureRequest {
  filename: string;
}

export interface UpdateMyRestaurantRequest {
  restaurant: Blob;
  menu: Blob;
  name: string;
  description: string;
  discountValue: number;
  discountType: UpdateMyRestaurantDiscountTypeEnum;
  address: string;
  restaurantZipcode: string;
  restaurantDepartment: number;
  googlePlacesId: string;
  localisationLat: number;
  localisationLng: number;
}

export interface UpdateMyRestaurantMenuRequest {
  menu: Blob;
}

export interface UpdateMyRestaurantPictureRequest {
  menu: Blob;
}

export interface UpdateOwnerProfileRequest {
  updateRestaurantOwnerProfileDto: UpdateRestaurantOwnerProfileDto;
}

/**
 *
 */
export class RestaurantOwnersApi /* extends runtime.BaseAPI */ {
  /**
   */
  static createJobOffer({ offer }: CreateJobOfferRequest): Promise<void> {
    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (offer !== undefined) {
      formParams.append("offer", offer);
    }
    return rest.formData(
      `/restaurant-owners/job-offer`,
      formParams,
      "post",
      true
    );
  }

  /**
   */
  static getMyRestaurant(): Promise<GetMyRestaurantDto> {
    return rest.get(`/restaurant-owners/restaurant`);
  }

  static getCreationStats(data: any): Promise<any> {
    return rest.post(`/restaurant-owners/creation-stats`, data);
  }
  static getJobOfferStats(data: any): Promise<any> {
    return rest.post(`/restaurant-owners/job-offers-stats`, data);
  }

  static getClientStats(data: any): Promise<any> {
    return rest.post(`/restaurant-owners/client-stats`, data);
  }

  static Discount(data: any): Promise<any> {
    return rest.post(`/restaurant-owners/discount`);
  }

  /**
   */
  static scanQrCode({
    savingScannedQrCodeDataDto,
  }: ScanQrCodeRequest): Promise<ScanSavindQrCodeResponseDto> {
    return rest.post(
      `/restaurant-owners/qr-code/scan/saving`,
      savingScannedQrCodeDataDto
    );
  }

  /**
   */
  static scanRetroQrCode({
    savingScannedQrCodeDataDto,
  }: ScanRetroQrCodeRequest): Promise<ScanSavindQrCodeResponseDto> {
    return rest.post(
      `/restaurant-owners/qr-code/scan/retrocessing`,
      savingScannedQrCodeDataDto
    );
  }

  /**
   */
  static serveJobOffer({ filename }: ServeJobOfferRequest): Promise<void> {
    return rest.get(
      `/restaurant-owners/restaurant-job-offer/${encodeURIComponent(filename)}`
    );
  }

  /**
   */
  static serveRestaurantPicture({
    filename,
  }: ServeRestaurantPictureRequest): Promise<void> {
    return rest.get(
      `/restaurant-owners/restaurant-pictures/${encodeURIComponent(filename)}`
    );
  }

  /**
   */
  static updateMyRestaurant({
    restaurant,
    menu,
    name,
    description,
    discountValue,
    discountType,
    address,
    restaurantZipcode,
    restaurantDepartment,
    googlePlacesId,
    localisationLat,
    localisationLng,
  }: UpdateMyRestaurantRequest): Promise<void> {
    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (restaurant !== undefined) {
      formParams.append("restaurant", restaurant);
    }
    if (menu !== undefined) {
      formParams.append("menu", menu);
    }
    if (name !== undefined) {
      formParams.append("name", name);
    }
    if (description !== undefined) {
      formParams.append("description", description);
    }
    if (discountValue !== undefined) {
      formParams.append("discount_value", discountValue);
    }
    if (discountType !== undefined) {
      formParams.append("discount_type", discountType);
    }
    if (address !== undefined) {
      formParams.append("address", address);
    }
    if (restaurantZipcode !== undefined) {
      formParams.append("restaurant_zipcode", restaurantZipcode);
    }
    if (restaurantDepartment !== undefined) {
      formParams.append("restaurant_department", restaurantDepartment);
    }
    if (googlePlacesId !== undefined) {
      formParams.append("google_places_id", googlePlacesId);
    }
    if (localisationLat !== undefined) {
      formParams.append("localisationLat", localisationLat);
    }
    if (localisationLng !== undefined) {
      formParams.append("localisationLng", localisationLng);
    }
    return rest.formData(
      `/restaurant-owners/restaurant`,
      formParams,
      "put",
      true
    );
  }

  /**
   */
  static updateMyRestaurantMenu({
    menu,
  }: UpdateMyRestaurantMenuRequest): Promise<void> {
    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (menu !== undefined) {
      formParams.append("menu", menu);
    }
    return rest.formData(
      `/restaurant-owners/restaurant/update-menu`,
      formParams,
      "patch",
      true
    );
  }

  /**
   */
  static updateMyRestaurantPicture({
    menu,
  }: UpdateMyRestaurantPictureRequest): Promise<void> {
    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (menu !== undefined) {
      formParams.append("menu", menu);
    }
    return rest.formData(
      `/restaurant-owners/restaurant/update-picture`,
      formParams,
      "patch",
      true
    );
  }

  /**
   * Update one or many fields of profile
   */
  static updateOwnerProfile({
    updateRestaurantOwnerProfileDto,
  }: UpdateOwnerProfileRequest): Promise<void> {
    return rest.patch(
      `/restaurant-owners/profile`,
      updateRestaurantOwnerProfileDto,
      true
    );
  }
}

/**
 * @export
 * @enum {string}
 */
export type UpdateMyRestaurantDiscountTypeEnum = "percent" | "amount";
