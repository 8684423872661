//@ts-nocheck
import { Box, Button, ButtonGroup, FormControlLabel, FormLabel, Switch } from "@material-ui/core"
import { KeyboardDatePicker } from "@material-ui/pickers"
import React, { useContext, useEffect, useState } from "react"
import { format } from "date-fns"

export interface DateRange {
    startDate: Date
    endDate: Date
}

interface Props {
    value: DateRange
    onChange: (value: DateRange) => void
}

function DateRangePicker(props: Props): JSX.Element {
    const { value, onChange } = props
    const [internalStartDate, setInternalStartDate] = useState<Date | undefined>()
    const [internalEndDate, setInternalEndDate] = useState<Date | undefined>()

    useEffect(() => setInternalStartDate(value.startDate), [value.startDate])
    useEffect(() => setInternalEndDate(value.endDate), [value.endDate])

    const onDateChange = (startDate?: Date, endDate?: Date) => {
        const hasInvalidDate = (startDate && isNaN(startDate?.valueOf())) || (endDate && isNaN(endDate?.valueOf()))
        if (hasInvalidDate) return

        if (startDate !== undefined && endDate !== undefined && startDate > endDate) {
            if (startDate !== value.startDate) {
                onChange({
                    startDate,
                    endDate: startDate,
                })
            } else {
                setInternalStartDate(startDate)
                setInternalEndDate(endDate)
            }
        } else if (startDate !== value.startDate || endDate !== value.endDate) {
            if (startDate && endDate) {
                onChange({
                    startDate,
                    endDate,
                })
            }
        }
    }

    return (
        <Box mt={1} display="flex" alignItems="center" justifyContent="space-between" maxWidth={500}>
            <Box mb={1}>
                <FormLabel htmlFor="start-date">Start date</FormLabel>
                <Box>
                    <KeyboardDatePicker
                        id="start-date"
                        value={internalStartDate ?? null}
                        format="dd/MM/yyyy"
                        onChange={(start) => onDateChange(start ?? undefined, value.endDate ?? internalEndDate)}
                    />
                </Box>
            </Box>

            <Box mb={1}>
                <FormLabel htmlFor="end-date">End date</FormLabel>
                <Box>
                    <KeyboardDatePicker
                        id="end-date"
                        value={internalEndDate ?? null}
                        format="dd/MM/yyyy"
                        onChange={(end) => onDateChange(value.startDate ?? internalStartDate, end ?? undefined)}
                        minDate={internalStartDate}
                        minDateMessage="The end date must be greater or equal to the start date"
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default DateRangePicker
