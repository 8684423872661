//@ts-nocheck
import { Box, BoxProps, Container } from "@material-ui/core"
import React from "react"

type Props = Omit<BoxProps, "component" | "pl" | "pr" | "pt" | "title"> & {
    title?: JSX.Element | string
    box?: Omit<BoxProps, "children">
}

const BaseContainer = ({ children, title, box, ...props }: Props) => {
    return (
        <Box {...props} component={Container} maxWidth="xl" pl={10} pr={10} pt={5}>
            <Box {...box}>{children}</Box>
        </Box>
    )
}
export default BaseContainer
