//@ts-nocheck
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";

import React, { useCallback, useMemo, useState, useEffect } from "react";
import BaseContainer from "../../components/BaseContainer";
import { api, snackbarCatch } from "../../services/api";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  AdminGetAmbassadorStatsSortByEnum,
  AdminGetAmbassadorStatsSortDirectionEnum,
  AdminGetAmbassadorStatsDto,
} from "../../services/openapi";
import {
  AddRounded,
  DeleteRounded,
  ExpandMoreRounded,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import ApproveProfileModal from "./ApproveProfileModal";
import { useCreateCreatorCode } from "../../services/useCreateCreatorCode";
import CreatorCodeModal from "./CreatorCode";

const defaultParams: {
  page: number;
  limit: number;
  sortBy: AdminGetAmbassadorStatsSortByEnum;
  sortDirection: AdminGetAmbassadorStatsSortDirectionEnum;
} = {
  page: 1,
  limit: 10,
  sortBy: "creator_code",
  sortDirection: "asc",
};

const AmbassadorScene = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(defaultParams.page);
  const [limit, setLimit] = useState(defaultParams.limit);
  const [isCreatorCodeModalOpen, setCreatorCodeModalOpen] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [sorting, setSorting] = useState<{
    sortBy: AdminGetAmbassadorStatsSortByEnum;
    sortDirection: AdminGetAmbassadorStatsSortDirectionEnum;
  }>({
    sortBy: defaultParams.sortBy,
    sortDirection: defaultParams.sortDirection,
  });
  const [modalData, setModalData] = useState(null as null | string);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null); // State for the month picker

  const handleMonthChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const month = event.target.value;
      setSelectedMonth(month || null);
    },
    [setSelectedMonth]
  );
  const onSortChanged = useCallback(
    (
      column: TableColumn<AdminGetAmbassadorStatsDto>,
      direction: "asc" | "desc"
    ) => {
      setSorting({
        sortBy: column.sortField! as AdminGetAmbassadorStatsSortByEnum,
        sortDirection: direction,
      });
    },
    [setSorting]
  );

  const ambassadorsQuery = api.ambassador.useAmbassadors({
    page,
    limit,
    ...sorting,
  });

  const monthlyAmbassadorsQuery = api.ambassador.useMonthlyAmbassadors({
    page,
    limit,
    sortBy: sorting.sortBy,
    sortDirection: sorting.sortDirection,
    month: selectedMonth || "",
  });

  const ambassadors = selectedMonth
    ? monthlyAmbassadorsQuery
    : ambassadorsQuery;

  useEffect(() => {
    console.log(ambassadors);
  }, [ambassadors]);

  const { mutateAsync: approveAmbassadorMutation } =
    api.ambassador.useApproveAmbassador();

  const approveAmbassador = useCallback(
    async (ambassadorId: string) => {
      try {
        await approveAmbassadorMutation({
          ambassadorId,
        });
        enqueueSnackbar("Ambassadeur approuvé", { variant: "success" });
      } catch (e) {
        snackbarCatch(e, enqueueSnackbar);
      }
    },
    [approveAmbassadorMutation]
  );

  const { mutateAsync: createCreatorCode, isLoading: isCreating } =
    useCreateCreatorCode();

  // Handle the change in the input field
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTextFieldValue(event.target.value);
  };

  const handleCreatorCodeSubmit = useCallback(async () => {
    if (!textFieldValue.trim()) {
      enqueueSnackbar("Veuillez entrer un code créateur", { variant: "error" });
      return;
    }

    try {
      // Call the mutate function from the hook to create the creator code
      await createCreatorCode({ creatorCode: textFieldValue });
      enqueueSnackbar("Code créateur ajouté avec succès", {
        variant: "success",
      });
      setTextFieldValue("");
      ambassadorsQuery.refetch();
    } catch (e) {
      enqueueSnackbar("Erreur lors de l'ajout du code créateur", {
        variant: "error",
      });
    }
  }, [textFieldValue, createCreatorCode, enqueueSnackbar]);

  const columns = useMemo<TableColumn<AdminGetAmbassadorStatsDto>[]>(
    () => [
      {
        name: "code de création",
        selector: (row) => row.creator_code,
        sortField: "creator_code",
        sortable: true,
      },
      {
        name: "Ambassadeur",
        selector: (row) => row.ambassador_username || row.username,
        sortField: "ambassador_username",
        sortable: true,
      },
      {
        name: "Nombre Affiliation",
        selector: (row) => row.referral_count,
      },
      // {
      //   name: "Nb commentaires",
      //   selector: (row) =>
      //     !row.need_approbation && row.comment_count ? row.comment_count : "-",
      //   sortField: "comment_count",
      //   sortable: true,
      // },
      {
        name: "Nombre like/dislike",
        selector: (row) =>
          !row.need_approbation
            ? `${row.like_count || 0} / ${row.dislike_count || 0}`
            : "-",
      },

      // {
      //   name: "Nb abonnements",
      //   selector: (row) =>
      //     !row.need_approbation && row.follower_count
      //       ? row.follower_count
      //       : "-",
      //   sortField: "follower_count",
      //   sortable: true,
      // },
      {
        name: "Nombre de lancement de quête",
        selector: (row) => row.quest_count,
      },
      {
        name: "Nombre de cadeaux",
        selector: (row) => row.gift_creator,
      },
    ],
    [approveAmbassador]
  );

  if (ambassadors.isLoading || ambassadors.data === undefined) {
    return (
      <BaseContainer>
        <Typography variant="h3" component="h1">
          Activité ambassadeurs
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress />
        </Box>
      </BaseContainer>
    );
  }

  return (
    <BaseContainer>
      <Typography variant="h3" component="h1">
        Activité ambassadeurs
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          type="month"
          value={selectedMonth || ""}
          onChange={handleMonthChange}
          style={{ marginRight: "15px" }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" style={{ marginRight: "10px" }}>
            Code créateur:
          </Typography>
          <TextField
            style={{ marginBottom: "18px" }}
            value={textFieldValue}
            onChange={handleTextFieldChange}
          />
          <IconButton onClick={handleCreatorCodeSubmit}>
            <AddIcon />
          </IconButton>
          <IconButton onClick={() => setCreatorCodeModalOpen(true)}>
            <VisibilityIcon />
          </IconButton>
          <CreatorCodeModal
            open={isCreatorCodeModalOpen}
            onClose={() => setCreatorCodeModalOpen(false)}
          />
          <Dialog>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <Typography>
                Êtes-vous sûr de vouloir rajouter ce code?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button>Confirmer</Button>
              <Button>Annuler</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <ApproveProfileModal
        ambassadorId={modalData}
        onClose={() => setModalData(null)}
      />

      <Box mt={3} />

      <DataTable
        persistTableHead
        // noDataComponent
        columns={columns}
        data={ambassadors.data.data}
        responsive
        onSort={onSortChanged}
        sortServer
        noContextMenu
        noHeader
        expandOnRowDoubleClicked
        selectableRowsHighlight
        highlightOnHover
        sortIcon={<ExpandMoreRounded />}
        selectableRowsComponentProps={{ color: "primary" }}
        // onRowClicked
        pagination
        paginationServer
        paginationPerPage={ambassadors.data.limit}
        paginationTotalRows={ambassadors.data.totalCount}
        paginationRowsPerPageOptions={[5, 10, 20]}
        paginationDefaultPage={defaultParams.page}
        defaultSortAsc={defaultParams.sortDirection === "asc"}
        defaultSortFieldId={defaultParams.sortBy}
        onChangeRowsPerPage={setLimit}
        onChangePage={setPage}
        // fixedHeader
        // fixedHeaderScrollHeight
        keyField="ambassador_id"
      />
    </BaseContainer>
  );
};
export default AmbassadorScene;
