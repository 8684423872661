/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"

export interface StripeWebhookRequest {
    stripeSignature: string
}

/**
 *
 */
export class BillingApi /* extends runtime.BaseAPI */ {
    /**
     * stripe webhook
     */
    static stripeWebhook({}: StripeWebhookRequest): Promise<void> {
        return rest.post(`/billing/stripe/webhook`, undefined, true)
    }
}
