//@ts-nocheck
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  Table,
  InputLabel,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import BaseContainer from "../../components/BaseContainer";
import { api, snackbarCatch } from "../../services/api";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  YourCagnotteQueryDto,
  AdminGetNewRestaurantsSortByEnum,
  AdminGetNewRestaurantsSortDirectionEnum,
  GetVoucherDto,
  RestaurantOwnersApi,
} from "../../services/openapi";
import {
  AddRounded,
  DeleteRounded,
  ExpandMoreRounded,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import DateRangePicker from "../../components/DateRangePicker";

const defaultParams: {
  sortBy: string;
  sortDirection: AdminGetNewRestaurantsSortDirectionEnum;
  page: number;
  limit: number;
} = {
  sortBy: "created_at",
  sortDirection: "asc",
  page: 1,
  limit: 10,
};

const AttributedScene = () => {
  const [page, setPage] = useState(defaultParams.page);
  const [limit, setLimit] = useState(defaultParams.limit);
  const [textFieldValue, setTextFieldValue] = useState("");
  const { mutateAsync: ajoutAdmobMutation } = api.cagnotte.useAjoutAdmob();
  const { enqueueSnackbar } = useSnackbar();
  api.owner.TotalDiscount();

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleAddAdmob = async () => {
    setOpenConfirmation(true);
  };

  const confirmAddAdmob = async () => {
    try {
      // Use the captured value from the TextField
      const dataFromTextField = textFieldValue;

      // Call the mutation function
      await ajoutAdmobMutation({
        ajoutAdmobDto: {
          amount: Number(dataFromTextField),
        }, // Adjust the payload structure as needed
      });

      // Handle the result if needed

      // Optionally, clear the TextField value after successful mutation
      setTextFieldValue("");

      // Close the confirmation dialog
      setOpenConfirmation(false);
      window.location.reload();
    } catch (error) {
      // Handle errors
      console.error("Error adding Admob:", error);

      // Close the confirmation dialog in case of an error
      setOpenConfirmation(false);
    }
  };

  const cancelAddAdmob = () => {
    // Close the confirmation dialog
    setOpenConfirmation(false);
    setTextFieldValue("");
  };

  const [sorting, setSorting] = useState<{
    sortBy: string;
    sortDirection: AdminGetNewRestaurantsSortDirectionEnum;
  }>({
    sortBy: defaultParams.sortBy,
    sortDirection: defaultParams.sortDirection,
  });

  let savings = api.cagnotte.useCagnotte({
    page,
    limit,
  });

  const onSortChanged = (
    column: TableColumn<YourCagnotteQueryDto>,
    direction: "asc" | "desc"
  ) => {
    setSorting({
      sortBy: column.sortField!,
      sortDirection: direction,
    });
  };

  const columns = useMemo<TableColumn<YourCagnotteQueryDto>[]>(
    () => [
      {
        name: "Date",
        selector: (row) =>
          format(new Date(row.date_column), "MMM yyyy", { locale: fr }),
      },
      {
        name: (
          <>
            Cagnotte début
            <br />
            du mois
          </>
        ),
        selector: (row) => row.column2,
      },
      {
        name: (
          <>
            Augmentation de
            <br />
            cagnotte
          </>
        ),
        selector: (row) => row.column3,
      },
      {
        name: (
          <>
            Diminution de
            <br />
            cagnotte
          </>
        ),
        selector: (row) => row.column4,
      },
      {
        name: (
          <>
            Cagnotte
            <br />
            disponible
          </>
        ),
        selector: (row) => row.column2 + row.column3 - row.column4,
      },
      {
        name: (
          <>
            Mouv en circulation
            <br />
            non encore dépensés
          </>
        ),
        selector: (row) => row.column6,
      },
      {
        name: (
          <>
            Mouv encaissés
            <br />
            par restaurateur
          </>
        ),
        selector: (row) => row.column7,
      },
      {
        name: "Abondement",
        selector: (row) => row.column8,
      },
      {
        name: (
          <>
            Total capitaux
            <br />
            en circulation
          </>
        ),
        selector: (row) =>
          row.column2 + row.column3 - row.column4 + row.column6 + row.column7,
      },
      {
        name: (
          <>
            Total sommes
            <br />
            dues
          </>
        ),
        selector: (row) =>
          (
            (row.column2 +
              row.column3 -
              row.column4 +
              row.column6 +
              row.column7) *
            1.5
          )?.toFixed(2),
      },
    ],
    []
  );

  if (savings.isLoading || savings.data === undefined) {
    return (
      <BaseContainer>
        <Typography variant="h3" component="h1">
          Bons d'échange suivi des capitaux
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress />
        </Box>
      </BaseContainer>
    );
  }
  const reversedData = [...savings.data.data].reverse();

  return (
    <BaseContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" component="h1">
          Bons d'échange suivi des capitaux
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" style={{ marginRight: "10px" }}>
            Modification cagnotte pub AdMob
          </Typography>
          <TextField
            style={{ marginBottom: "18px" }}
            value={textFieldValue}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^-0-9]/g, "");
              setTextFieldValue(numericValue);
            }}
            inputProps={{ pattern: "-?[0-9]*" }}
          />
          <IconButton onClick={handleAddAdmob}>
            <AddIcon />
          </IconButton>
          <Dialog open={openConfirmation} onClose={cancelAddAdmob}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              {/* Add content for confirmation */}
              <Typography>
                Êtes-vous sûr de vouloir modifier la cagnotte?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={confirmAddAdmob}>Confirmer</Button>
              <Button onClick={cancelAddAdmob}>Annuler</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <Box mt={3} />

      <DataTable
        persistTableHead
        // noDataComponent
        columns={columns}
        data={reversedData}
        responsive
        onSort={onSortChanged}
        sortServer
        noContextMenu
        noHeader
        expandOnRowDoubleClicked
        selectableRowsHighlight
        highlightOnHover
        sortIcon={<ExpandMoreRounded />}
        // onRowClicked
        paginationPerPage={savings.data.limit}
        paginationTotalRows={savings.data.totalCount}
        paginationRowsPerPageOptions={[5, 10, 20]}
        paginationDefaultPage={defaultParams.page}
        defaultSortAsc={defaultParams.sortDirection === "asc"}
        defaultSortFieldId={defaultParams.sortBy}
        onChangeRowsPerPage={setLimit}
        onChangePage={setPage}
        // fixedHeader
        // fixedHeaderScrollHeight
        keyField="owner_id"
      />
    </BaseContainer>
  );
};
export default AttributedScene;
