/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { AuctionDay, CreateAuctionRestaurantDto } from "../models"

export interface GetAuctionRequest {
    zipCode: string
}

export interface SetAuctionRequest {
    createAuctionRestaurantDto: CreateAuctionRestaurantDto
}

/**
 *
 */
export class AuctionsRestaurantApi /* extends runtime.BaseAPI */ {
    /**
     */
    static getAuction({ ...queryParameters }: GetAuctionRequest): Promise<Array<AuctionDay>> {
        return rest.get(`/auctions-restaurant?${rest.encodeObjectToUrl(queryParameters)}`)
    }

    /**
     */
    static getSetupIntent(): Promise<object> {
        return rest.get(`/auctions-restaurant/setup-intent`)
    }

    /**
     */
    static setAuction({ createAuctionRestaurantDto }: SetAuctionRequest): Promise<Array<AuctionDay>> {
        return rest.post(`/auctions-restaurant`, createAuctionRestaurantDto)
    }
}
