/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import { GetAdStatsDto } from "../models"

export interface CreateAdRequest {
    ad: Blob
    gender: CreateAdGenderEnum
    ageMin: number
    ageMax: number
    department: number
    budget: number
    canDiffuse: boolean
    onClickUrl: string
}

export interface ServeAdVideoRequest {
    filename: string
}

export interface UpdateAdRequest {
    ad: Blob
    gender?: UpdateAdGenderEnum
    ageMin?: number
    ageMax?: number
    department?: number
    budget?: number
    canDiffuse?: boolean
    onClickUrl?: string
}

/**
 *
 */
export class AdvertisersApi /* extends runtime.BaseAPI */ {
    /**
     */
    static adInfos(): Promise<void> {
        return rest.get(`/advertisers/infos`)
    }

    /**
     */
    static adStats(): Promise<GetAdStatsDto> {
        return rest.get(`/advertisers/stats`)
    }

    /**
     */
    static createAd({
        ad,
        gender,
        ageMin,
        ageMax,
        department,
        budget,
        canDiffuse,
        onClickUrl,
    }: CreateAdRequest): Promise<object> {
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }]
        const canConsumeForm = runtime.canConsumeForm(consumes)

        let formParams: { append(param: string, value: any): any }
        let useForm = false
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm
        if (useForm) {
            formParams = new FormData()
        } else {
            formParams = new URLSearchParams()
        }

        if (ad !== undefined) {
            formParams.append("ad", ad)
        }
        if (gender !== undefined) {
            formParams.append("gender", gender)
        }
        if (ageMin !== undefined) {
            formParams.append("age_range_min", ageMin)
        }
        if (ageMax !== undefined) {
            formParams.append("age_range_max", ageMax)
        }
        if (department !== undefined) {
            formParams.append("department", department)
        }
        if (budget !== undefined) {
            formParams.append("budget", budget)
        }
        if (canDiffuse !== undefined) {
            formParams.append("can_diffuse", canDiffuse)
        }
        if (onClickUrl !== undefined) {
            formParams.append("on_click_url", onClickUrl)
        }
        return rest.formData(`/advertisers/ad`, formParams, "post")
    }

    /**
     */
    static serveAdVideo({ filename }: ServeAdVideoRequest): Promise<void> {
        return rest.get(`/advertisers/ad/${encodeURIComponent(filename)}`)
    }

    /**
     */
    static stopCampaign(): Promise<void> {
        return rest.post(`/advertisers/stop-campaign`, undefined, true)
    }

    /**
     */
    static updateAd({
        ad,
        gender,
        ageMin,
        ageMax,
        department,
        budget,
        canDiffuse,
        onClickUrl,
    }: UpdateAdRequest): Promise<object> {
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }]
        const canConsumeForm = runtime.canConsumeForm(consumes)

        let formParams: { append(param: string, value: any): any }
        let useForm = false
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm
        if (useForm) {
            formParams = new FormData()
        } else {
            formParams = new URLSearchParams()
        }

        if (ad !== undefined) {
            formParams.append("ad", ad)
        }
        if (gender !== undefined) {
            formParams.append("gender", gender)
        }
        if (ageMin !== undefined) {
            formParams.append("age_range_min", ageMin)
        }
        if (ageMax !== undefined) {
            formParams.append("age_range_max", ageMax)
        }
        if (department !== undefined) {
            formParams.append("department", department)
        }
        if (budget !== undefined) {
            formParams.append("budget", budget)
        }
        if (canDiffuse !== undefined) {
            formParams.append("can_diffuse", canDiffuse)
        }
        if (onClickUrl !== undefined) {
            formParams.append("on_click_url", onClickUrl)
        }
        return rest.formData(`/advertisers/ad`, formParams, "put")
    }

    /**
     */
    static validateAd(): Promise<void> {
        return rest.put(`/advertisers/validate`, undefined, true)
    }
}

/**
 * @export
 * @enum {string}
 */
export type CreateAdGenderEnum = "male" | "female"
/**
 * @export
 * @enum {string}
 */
export type UpdateAdGenderEnum = "male" | "female"
