//@ts-nocheck
import {
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import BaseContainer from "../../components/BaseContainer"
import { api, snackbarCatch } from "../../services/api"
import DataTable, { TableColumn } from "react-data-table-component"
import { GetVoucherDto } from "../../services/openapi"
import { AddRounded, DeleteRounded, ExpandMoreRounded } from "@material-ui/icons"
import { useSnackbar } from "notistack"

const MooverExchangeOrderScene = () => {
    const { enqueueSnackbar } = useSnackbar()

    const moovers = api.moover.useMooverExchangeOrder()

    if (moovers.isLoading || moovers.data === undefined) {
        return (
            <BaseContainer>
                <Typography variant="h3" component="h1">
                    Requêtes sur bons déchange accordé
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <CircularProgress />
                </Box>
            </BaseContainer>
        )
    }

    return (
        <BaseContainer>
            <Typography variant="h3" component="h1">
                Requêtes sur bons déchange accordé
            </Typography>

            <Box mt={3}>
                <TableContainer component={Paper} style={{ width: "30%" }}>
                    <Table aria-label="gender">
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">Nombre de Moovers concernés</TableCell>
                                <TableCell align="center">{moovers.data.moover_count}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell align="center">Total des bons accordés</TableCell>
                                <TableCell align="center">{moovers.data.total_granted}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell align="center">Montant max accordé</TableCell>
                                <TableCell align="center">{moovers.data.max_amount_granted}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell align="center">Montant min accordé</TableCell>
                                <TableCell align="center">{moovers.data.min_amount_granted}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </BaseContainer>
    )
}
export default MooverExchangeOrderScene
