//@ts-nocheck
import { Box, Button, CircularProgress, Container, IconButton, TextField, Typography } from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import BaseContainer from "../../components/BaseContainer"
import { api, snackbarCatch } from "../../services/api"
import DataTable, { TableColumn } from "react-data-table-component"
import { GetVoucherDto } from "../../services/openapi"
import { DeleteRounded, ExpandMoreRounded } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import BaseModal from "../../components/modal/BaseModal"
import FormSection from "../../components/form/FormSection"
import { Controller, useForm } from "react-hook-form"

type CreateVoucherForm = {
    code: string
    amount: string
}

type Props = {
    isOpen: boolean
    onClose: () => void
}
const CreateVoucherModal = (props: Props) => {
    const { isOpen, onClose } = props
    const { formState, control, handleSubmit } = useForm<CreateVoucherForm>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            amount: "",
            code: "",
        },
    })
    const canSubmit = formState.isValid && formState.isDirty
    const { enqueueSnackbar } = useSnackbar()
    const { mutateAsync: createVoucherMutation } = api.voucher.useCreateVoucher()
    const createVoucher = handleSubmit(async (form: CreateVoucherForm) => {
        try {
            await createVoucherMutation({
                createVoucherDto: {
                    code: form.code,
                    amount: Number(form.amount),
                },
            })
            onClose()
        } catch (e) {
            snackbarCatch(e, enqueueSnackbar)
        }
    })

    return (
        <BaseModal isOpen={isOpen} onClose={onClose} title="Créer un bon cadeau" height="auto" width="400px">
            <Box p={3} component="form" onSubmit={createVoucher}>
                <FormSection name="Code" htmlFor="code-textfield" mt={0}>
                    <Controller
                        control={control}
                        rules={{ required: true }}
                        name="code"
                        render={({ field }) => <TextField fullWidth id="code-textfield" {...field} />}
                    />
                </FormSection>
                <FormSection name="Amount" htmlFor="amount-textfield">
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                            validate: (v) => !isNaN(Number(v)) && Number(v) > 0,
                        }}
                        name="amount"
                        render={({ field: { ref, ...field }, fieldState }) => (
                            <TextField fullWidth id="amount-textfield" {...field} error={fieldState.invalid} />
                        )}
                    />
                </FormSection>
                <Box mt={3}>
                    <Button variant="contained" type="submit" disabled={!canSubmit}>
                        Create
                    </Button>
                </Box>
            </Box>
        </BaseModal>
    )
}
export default CreateVoucherModal
