/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime";
import rest from "../../http";
import {
  AdminGetAdvertiserStatsDto,
  AdminGetDraftAmbassadorProfile,
  AdminGetNewRestaurantsDto,
  AdminGetOwnerStatsDto,
  AdminLoginUserDto,
  AdminUpdateUserDto,
  CreateVoucherDto,
  AjoutAdmobDto,
  GetAdmobDto,
  GetMooverExchangeOrderDto,
  GetMooverStatsDto,
  PaginatedAdminGetAdvertiserBudgetDto,
  PaginatedAdminGetAmbassadorStatsDto,
  PaginatedAdminGetOwnerExchangeOrderDto,
  PaginatedAdminGetOwnerExpenseDto,
  PaginatedAdminGetUserDto,
  PaginatedGetMooverExchangeOrderAttributedDto,
  PaginatedGetVoucherDto,
  PaginatedGetCagnotteDto,
  PaginatedAdmobDto

} from "../models";

export interface AdminApproveAmbassadorRequest {
  ambassadorId: string;
}


export interface AdminApproveAmbassadorDraftProfileRequest {
  ambassadorId: string;
}

export interface AdminCreateVoucherRequest {
  createVoucherDto: CreateVoucherDto;
}

export interface AdminAjoutAdmobRequest {
  ajoutAdmobDto: AjoutAdmobDto;
}


export interface AdminDeleteUserRequest {
  userId: string;
}

export interface AdminUpdateUserVisibilityRequest {
  userId: string;
  visible: boolean;
}

export interface AdminDeleteVoucherRequest {
  code: string;
}

export interface AdminGetAdvertiserBudgetRequest {
  page: number;
  limit: number;
  startDate: string;
  endDate: string;
  sortDirection?: AdminGetAdvertiserBudgetSortDirectionEnum;
  sortBy?: AdminGetAdvertiserBudgetSortByEnum;
}

export interface AdminGetAdvertiserStatsRequest {
  startDate: string;
  endDate: string;
}

export interface AdminGetAmbassadorDraftProfileRequest {
  ambassadorId: string;
}

export interface AdminGetAmbassadorStatsRequest {
  page: number;
  limit: number;
  sortBy?: AdminGetAmbassadorStatsSortByEnum;
  hasDraftProfile?: boolean;
  needApprobation?: boolean;
  sortDirection?: AdminGetAmbassadorStatsSortDirectionEnum;
}

export interface AdminGetMooverExchangeOrderAttributedRequest {
  page: number;
  limit: number;
  sortDirection?: AdminGetMooverExchangeOrderAttributedSortDirectionEnum;
}

export interface AdminGetNewRestaurantsRequest {
  sortBy?: AdminGetNewRestaurantsSortByEnum;
  endDate?: string;
  sortDirection?: AdminGetNewRestaurantsSortDirectionEnum;
}

export interface AdminGetOwnerExchangeOrderRequest {
  page: number;
  limit: number;
  period: string;
  sortDirection?: AdminGetOwnerExchangeOrderSortDirectionEnum;
  sortBy?: AdminGetOwnerExchangeOrderSortByEnum;
}

export interface AdminGetOwnerExpensesRequest {
  page: number;
  limit: number;
  startDate: string;
  endDate: string;
  sortDirection?: AdminGetOwnerExpensesSortDirectionEnum;
  sortBy?: AdminGetOwnerExpensesSortByEnum;
}

export interface AdminGetOwnerStatsRequest {
  startDate: string;
  endDate: string;
}

export interface AdminGetUsersRequest {
  page: number;
  limit: number;
  sortBy?: AdminGetUsersSortByEnum;
  sortDirection?: AdminGetUsersSortDirectionEnum;
}

export interface AdminGetVouchersRequest {
  page: number;
  limit: number;
  sortBy?: AdminGetVouchersSortByEnum;
  createdBy?: string;
  code?: string;
  sortDirection?: AdminGetVouchersSortDirectionEnum;
}

export interface AdminGetCagnotteRequest {
  page: number;
  limit: number;
  sortBy?: AdminGetVouchersSortByEnum;
  createdBy?: string;
  code?: string;
  sortDirection?: AdminGetVouchersSortDirectionEnum;
}
export interface AdminGetAdmobRequest {
  page: number;
  limit: number;
  sortBy?: AdminGetVouchersSortByEnum;
  createdBy?: string;
  code?: string;
  sortDirection?: AdminGetVouchersSortDirectionEnum;
}


export interface AdminLoginRequest {
  adminLoginUserDto: AdminLoginUserDto;
}

export interface AdminMooverStatsRequest {
  startDate: string;
  endDate: string;
}

export interface AdminUpdateUserRequest {
  adminUpdateUserDto: AdminUpdateUserDto;
}

export interface AdminCreateCreatorCodeRequest {
  creatorCode: string;
}


export interface CreatorCodeDto {
  id: string;
  creator_code: string; 
  is_used: boolean;
  user_id: string; 
}


/**
 *
 */
export class AdminApi /* extends runtime.BaseAPI */ {

  static adminCreateCreatorCode({
    creatorCode,
  }: AdminCreateCreatorCodeRequest): Promise<void> {
    return rest.post("/admin/creator-codes", {
      creatorCode,
    });
  }


  static fetchCreatorCodes(): Promise<CreatorCodeDto[]> {
    return rest.get("/admin/creator-codes");
  }

  static deleteCreatorCode(creatorCode: string): Promise<void> {
    return rest.delete(`/admin/creator-codes/${creatorCode}`, {});
  }
  
  
  /**
   * approve ambassador
   */
  static adminApproveAmbassador({
    ambassadorId,
  }: AdminApproveAmbassadorRequest): Promise<void> {
    return rest.post(
      `/admin/ambassadors/${encodeURIComponent(ambassadorId)}`,
      undefined,
      true
    );
  }

  /**
   * approve draft profile
   */
  static adminApproveAmbassadorDraftProfile({
    ambassadorId,
  }: AdminApproveAmbassadorDraftProfileRequest): Promise<void> {
    return rest.post(
      `/admin/ambassadors/${encodeURIComponent(ambassadorId)}/profile/draft`,
      undefined,
      true
    );
  }

  /**
   * create a voucher
   */
  static adminCreateVoucher({
    createVoucherDto,
  }: AdminCreateVoucherRequest): Promise<void> {
    return rest.post(`/admin/vouchers`, createVoucherDto, true);
  }

  static adminAjoutAdmob({
    ajoutAdmobDto,
  }: AdminAjoutAdmobRequest): Promise<void> {
    return rest.post(`/admin/cagnotte`, ajoutAdmobDto, true);
  }


  /**
   * delete a user
   */
  static adminDeleteUser({ userId }: AdminDeleteUserRequest): Promise<void> {
    return rest.delete(`/admin/users/${encodeURIComponent(userId)}`, undefined);
  }

  static adminUpdateUserVisibility({
    userId,
    visible,
  }: AdminUpdateUserVisibilityRequest): Promise<void> {
    return rest.put(`/admin/users/visible/${encodeURIComponent(userId)}`, {
      visible,
    });
  }

  /**
   * delete a voucher
   */
  static adminDeleteVoucher({
    code,
  }: AdminDeleteVoucherRequest): Promise<void> {
    return rest.delete(
      `/admin/vouchers/${encodeURIComponent(code)}`,
      undefined
    );
  }

  /**
   * get advertisers\' budget
   */
  static adminGetAdvertiserBudget({
    ...queryParameters
  }: AdminGetAdvertiserBudgetRequest): Promise<PaginatedAdminGetAdvertiserBudgetDto> {
    return rest.get(
      `/admin/vertisers/budget?${rest.encodeObjectToUrl(queryParameters)}`
    );
  }

  /**
   * get advertisers\' stats
   */
  static adminGetAdvertiserStats({
    ...queryParameters
  }: AdminGetAdvertiserStatsRequest): Promise<AdminGetAdvertiserStatsDto> {
    return rest.get(
      `/admin/vertisers/stats?${rest.encodeObjectToUrl(queryParameters)}`
    );
  }

  /**
   * get draft profile
   */
  static adminGetAmbassadorDraftProfile({
    ambassadorId,
  }: AdminGetAmbassadorDraftProfileRequest): Promise<AdminGetDraftAmbassadorProfile> {
    return rest.get(
      `/admin/ambassadors/${encodeURIComponent(ambassadorId)}/profile/draft`
    );
  }

  /**
   * get ambassador stats
   */
  static adminGetAmbassadorStats({
    ...queryParameters
  }: AdminGetAmbassadorStatsRequest): Promise<PaginatedAdminGetAmbassadorStatsDto> {
    return rest.get(
      `/admin/ambassadors/stats?${rest.encodeObjectToUrl(queryParameters)}`
    );
  }

  /**
   * get moover exchange orders
   */
  static adminGetMooverExchangeOrder(): Promise<GetMooverExchangeOrderDto> {
    return rest.get(`/admin/moovers/exchange-order`);
  }

  /**
   * get moover exchange orders attributed
   */
  static adminGetMooverExchangeOrderAttributed({
    ...queryParameters
  }: AdminGetMooverExchangeOrderAttributedRequest): Promise<PaginatedGetMooverExchangeOrderAttributedDto> {
    return rest.get(
      `/admin/moovers/exchange-order/attributed?${rest.encodeObjectToUrl(
        queryParameters
      )}`
    );
  }

  /**
   * get new restaurants
   */
  static adminGetNewRestaurants({
    ...queryParameters
  }: AdminGetNewRestaurantsRequest): Promise<Array<AdminGetNewRestaurantsDto>> {
    return rest.get(
      `/admin/owners/restaurants/new?${rest.encodeObjectToUrl(queryParameters)}`
    );
  }

  /**
   * get owner exchange orders
   */
  static adminGetOwnerExchangeOrder({
    ...queryParameters
  }: AdminGetOwnerExchangeOrderRequest): Promise<PaginatedAdminGetOwnerExchangeOrderDto> {
    return rest.get(
      `/admin/owners/exchange-order?${rest.encodeObjectToUrl(queryParameters)}`
    );
  }

  /**
   * get owner expenses
   */
  static adminGetOwnerExpenses({
    ...queryParameters
  }: AdminGetOwnerExpensesRequest): Promise<PaginatedAdminGetOwnerExpenseDto> {
    return rest.get(
      `/admin/owners/expenses?${rest.encodeObjectToUrl(queryParameters)}`
    );
  }

  /**
   * get owner stats
   */
  static adminGetOwnerStats({
    ...queryParameters
  }: AdminGetOwnerStatsRequest): Promise<AdminGetOwnerStatsDto> {
    return rest.get(
      `/admin/owners/stats?${rest.encodeObjectToUrl(queryParameters)}`
    );
  }

  /**
   * get many users
   */
  static adminGetUsers({
    ...queryParameters
  }: AdminGetUsersRequest): Promise<PaginatedAdminGetUserDto> {
    return rest.get(`/admin/users?${rest.encodeObjectToUrl(queryParameters)}`);
  }

  /**
   * get many vouchers
   */
  static adminGetVouchers({
    ...queryParameters
  }: AdminGetVouchersRequest): Promise<PaginatedGetVoucherDto> {
    return rest.get(
      `/admin/vouchers?${rest.encodeObjectToUrl(queryParameters)}`
    );
  }
  static adminGetCagnotte({
    ...queryParameters
  }: AdminGetCagnotteRequest): Promise<PaginatedGetCagnotteDto> {
    return rest.get(
      `/admin/cagnotte?${rest.encodeObjectToUrl(queryParameters)}`
    );
  }

  static adminGetAdmob({
    ...queryParameters
  }: AdminGetAdmobRequest): Promise<PaginatedAdmobDto> {
    return rest.get(
      `/admin/cagnotte?${rest.encodeObjectToUrl(queryParameters)}`
    );
    }

  /**
   * Log user in
   */
  static adminLogin({ adminLoginUserDto }: AdminLoginRequest): Promise<void> {
    return rest.post(`/admin/login`, adminLoginUserDto, true);
  }

  /**
   * Delete user\'s session
   */
  static adminLogout(): Promise<void> {
    return rest.post(`/admin/logout`, undefined, true);
  }

  /**
   * get stats about moovers connexions
   */
  static adminMooverStats({
    ...queryParameters
  }: AdminMooverStatsRequest): Promise<GetMooverStatsDto> {
    return rest.get(
      `/admin/moovers/stats?${rest.encodeObjectToUrl(queryParameters)}`
    );
  }

  /**
   * update a user
   */
  static adminUpdateUser({
    adminUpdateUserDto,
  }: AdminUpdateUserRequest): Promise<void> {
    return rest.put(`/admin/users`, adminUpdateUserDto, true);
  }
}

/**
 * @export
 * @enum {string}
 */
export type AdminGetAdvertiserBudgetSortDirectionEnum = "asc" | "desc";
/**
 * @export
 * @enum {string}
 */
export type AdminGetAdvertiserBudgetSortByEnum =
  | "username"
  | "ad_budger"
  | "ad_usage"
  | "ad_remaining_sold"
  | "created_at";
/**
 * @export
 * @enum {string}
 */
export type AdminGetAmbassadorStatsSortByEnum =
  | "username"
  | "ambassador_username"
  | "comment_count"
  | "like_count"
  | "follower_count"
  | "created_at";
/**
 * @export
 * @enum {string}
 */
export type AdminGetAmbassadorStatsSortDirectionEnum = "asc" | "desc";
/**
 * @export
 * @enum {string}
 */
export type AdminGetMooverExchangeOrderAttributedSortDirectionEnum =
  | "asc"
  | "desc";
/**
 * @export
 * @enum {string}
 */
export type AdminGetNewRestaurantsSortByEnum = "created_at" | "date";
/**
 * @export
 * @enum {string}
 */
export type AdminGetNewRestaurantsSortDirectionEnum = "asc" | "desc";
/**
 * @export
 * @enum {string}
 */
export type AdminGetOwnerExchangeOrderSortDirectionEnum = "asc" | "desc";
/**
 * @export
 * @enum {string}
 */
export type AdminGetOwnerExchangeOrderSortByEnum =
  | "company_name"
  | "created_at";
/**
 * @export
 * @enum {string}
 */
export type AdminGetOwnerExpensesSortDirectionEnum = "asc" | "desc";
/**
 * @export
 * @enum {string}
 */
export type AdminGetOwnerExpensesSortByEnum = "restaurant_name" | "created_at";
/**
 * @export
 * @enum {string}
 */
export type AdminGetUsersSortByEnum =
  | "email"
  | "username"
  | "role"
  | "created_at";
/**
 * @export
 * @enum {string}
 */
export type AdminGetUsersSortDirectionEnum = "asc" | "desc";
/**
 * @export
 * @enum {string}
 */
export type AdminGetVouchersSortByEnum = "code" | "amount" | "created_at";
/**
 * @export
 * @enum {string}
 */
export type AdminGetVouchersSortDirectionEnum = "asc" | "desc";
