//@ts-nocheck
import { Box, Button, CircularProgress, Container, IconButton, TextField, Typography } from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import BaseContainer from "../../components/BaseContainer"
import { api, snackbarCatch } from "../../services/api"
import DataTable, { TableColumn } from "react-data-table-component"
import { GetVoucherDto } from "../../services/openapi"
import { DeleteRounded, ExpandMoreRounded } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import BaseModal from "../../components/modal/BaseModal"
import FormSection from "../../components/form/FormSection"
import { Controller, useForm } from "react-hook-form"
import { BASE_API_URL } from "../../services/constants"

type ApproveProfileForm = {}

type Props = {
    onClose: () => void
    ambassadorId: string | null
}
const ApproveProfileModal = (props: Props) => {
    const { onClose, ambassadorId } = props
    const isOpen = ambassadorId !== null
    const { handleSubmit } = useForm<ApproveProfileForm>({
        mode: "onChange",
        reValidateMode: "onChange",
    })
    const ambassadorProfile = api.ambassador.useAmbassadorProfile(ambassadorId)
    const { enqueueSnackbar } = useSnackbar()
    const { mutateAsync: approveAmbassadorDraftProfileMutation } = api.ambassador.useApproveAmbassadorDraftProfile()
    const approveProfile = handleSubmit(async () => {
        if (!ambassadorId) return
        try {
            await approveAmbassadorDraftProfileMutation({
                ambassadorId,
            })
            enqueueSnackbar("Profile approuvé", { variant: "success" })
            onClose()
        } catch (e) {
            snackbarCatch(e, enqueueSnackbar)
        }
    })

    if (ambassadorProfile.isLoading || ambassadorProfile.data === undefined) {
        return (
            <BaseModal isOpen={isOpen} onClose={onClose} title="Approuver le profile" height="auto" width="400px">
                <Box mb={3} display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <CircularProgress />
                </Box>
            </BaseModal>
        )
    }

    return (
        <BaseModal isOpen={isOpen} onClose={onClose} title="Approuver le profile" height="auto" width="400px">
            <Box p={3} component="form" onSubmit={approveProfile}>
                <FormSection name="Username">{ambassadorProfile.data.username}</FormSection>
                <FormSection name="Description">{ambassadorProfile.data.description}</FormSection>
                <FormSection name="Facebook">{ambassadorProfile.data.facebook_account}</FormSection>
                <FormSection name="Instagram">{ambassadorProfile.data.instagram_account}</FormSection>
                <FormSection name="Twitter">{ambassadorProfile.data.twitter_account}</FormSection>
                <FormSection name="Image de profile">
                    {ambassadorProfile.data.profile_picture_filename && (
                        <img
                            src={BASE_API_URL + ambassadorProfile.data.profile_picture_filename}
                            alt="ambassador profile"
                            width={150}
                            height={150}
                        />
                    )}
                    {!ambassadorProfile.data.profile_picture_filename && "Pas d'image"}
                </FormSection>
                <Box mt={3}>
                    <Button variant="contained" type="submit">
                        Approve
                    </Button>
                </Box>
            </Box>
        </BaseModal>
    )
}
export default ApproveProfileModal
