//@ts-nocheck
import { AppBar, Box, IconButton, Link, Toolbar, Typography } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import React, { useContext } from "react"
import { Link as RouterLink } from "react-router-dom"

import { GetUserDto } from "../../services/openapi"
import PrivateNavbar from "./PrivateNavbar"
import PublicNavbar from "./PublicNavbar"
import { urls } from "../../services/urls"

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: "100vh",
            display: "flex",
            flexDirection: "column",
        },
        grow: {
            flexGrow: 1,
        },
        title: {
            width: "60px",
            display: "flex",
            justifyContent: "center",
        },
        logo: {
            maxHeight: "50px",
        },
    }),
)

interface Props {
    children: React.ReactNode | React.ReactNode[]
    navigation?: JSX.Element
}

const AppLayout = (props: Props): JSX.Element => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div>
                <AppBar position="static">
                    <Toolbar>
                        <Box display="flex" justifyContent="center">
                            <Link component={RouterLink} to={urls.users} underline="none" className={classes.title}>
                                <img src="/logo.png" alt="D.Mouv logo" className={classes.logo} />
                            </Link>
                        </Box>
                        <Box flexGrow={1} />
                        {props.navigation}
                    </Toolbar>
                </AppBar>
            </div>
            <main className={classes.grow}>{props.children}</main>
        </div>
    )
}

export default AppLayout
