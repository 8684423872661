export const urls = {
  exchangeOrder: {
    moovers: "/exchange-order/moovers",
    restaurants: "/exchange-order/restaurants",
    attributed: "/exchange-order/attributed",
    roue: "/exchange-order/roue",
  },
  moover: "/moover",
  ambassador: "/ambassador",
  sponsoring: "/sponsoring",
  restaurant: "/restaurant",
  ad: "/ad",
  users: "/users",
  voucher: "/voucher",
  account: {
    login: "/login",
  },
  statsCreation: "/stats-creation",
  statsJobOffers: "/stats-job-offers",
  statsClients: "/client-stats",
  statsSponsoring: "/sponsoring-stats",
};
