import { Button, Divider, MenuItem, Popover, SvgIconTypeMap, Typography } from "@material-ui/core"
import { OverridableComponent } from "@material-ui/core/OverridableComponent"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { ArrowDropDownRounded } from "@material-ui/icons"
import React from "react"
import { NavLink } from "react-router-dom"

const White = "#FFF"
const LightGrey = "#DDD"

const useStyles = makeStyles((theme) =>
    createStyles({
        activeLink: {
            color: `${theme.palette.action} !important`,
            outlineWidth: "3px",
            outline: "solid",
            outlineOffset: "-3px",
            backgroundColor: "#BBB",
            minWidth: "100px",
            display: "flex",
            justifyContent: "center",
        },
        inactiveLink: {
            "color": LightGrey,
            "&:hover": {
                color: White,
                backgroundColor: "#EEE",
                outlineWidth: "3px",
                outline: "solid",
            },
            "minWidth": "100px",
            "display": "flex",
            "justifyContent": "center",
            "outlineOffset": "-3px",
        },
        linkTypography: {
            fontWeight: 400,
        },
        iconSpacer: {
            marginRight: theme.spacing(0.5),
        },
        menuPaper: {
            marginTop: theme.spacing(1),
            border: `1px solid`,
            backgroundColor: theme.palette.primary.main,
            color: LightGrey,
        },
        dividerSubLink: {
            backgroundColor: "#555",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        dropDownIcon: {
            marginRight: "-0.5em",
        },
    }),
)

interface LinkIconProps {
    title: string
    url: string
    Icon?: OverridableComponent<SvgIconTypeMap>
    disabled?: boolean
}

export const LinkIcon = ({ title, url, Icon, disabled }: LinkIconProps) => {
    const classes = useStyles()

    return (
        <MenuItem
            component={NavLink}
            to={url}
            className={classes.inactiveLink}
            activeClassName={classes.activeLink}
            disabled={disabled}
        >
            {Icon && <Icon className={classes.iconSpacer} />}
            <Typography className={classes.linkTypography}>{title}</Typography>
        </MenuItem>
    )
}

export const MultiLinkIcon = ({
    title,
    options,
    Icon,
    active,
}: {
    title: string
    options: {
        url: string
        name: string
        disabled?: boolean
        onClick?: () => void
    }[]
    Icon: OverridableComponent<SvgIconTypeMap>
    active: boolean
}) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const menuId = `${title}-menu`
    return (
        <>
            <Popover
                id={menuId}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: classes.menuPaper }}
            >
                {options.map((option, index) => (
                    <React.Fragment key={option.name}>
                        {index !== 0 && <Divider className={classes.dividerSubLink} />}
                        <MenuItem
                            component={NavLink}
                            to={option.url}
                            className={classes.inactiveLink}
                            activeClassName={classes.activeLink}
                            disabled={option.disabled}
                            onClick={() => {
                                handleClose()
                                return option.onClick?.()
                            }}
                        >
                            <Typography className={classes.linkTypography}>{option.name}</Typography>
                        </MenuItem>
                    </React.Fragment>
                ))}
            </Popover>
            <MenuItem
                aria-controls={menuId}
                onClick={handleClickListItem}
                className={active ? classes.activeLink : classes.inactiveLink}
            >
                <Icon className={classes.iconSpacer} />
                <Typography className={classes.linkTypography}>{title}</Typography>
                <ArrowDropDownRounded className={classes.dropDownIcon} />
            </MenuItem>
        </>
    )
}
