//@ts-nocheck
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  Input,
  InputLabel,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import BaseContainer from "../../components/BaseContainer";
import { api } from "../../services/api";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  AdminGetNewRestaurantsDto,
  AdminGetNewRestaurantsSortByEnum,
  AdminGetNewRestaurantsSortDirectionEnum,
} from "../../services/openapi";
import { ExpandMoreRounded } from "@material-ui/icons";

import { format } from "date-fns";
import { fr } from "date-fns/locale";

import dayjs from "dayjs";
import Departments from "../../departments.json";
import { DatePicker } from "@material-ui/pickers";

const defaultParams: {
  sortBy: AdminGetNewRestaurantsSortByEnum;
  sortDirection: AdminGetNewRestaurantsSortDirectionEnum;
} = {
  sortBy: "created_at",
  sortDirection: "asc",
};

const CreationStats = () => {
  const [region, setRegion] = useState("france");
  const [department, setDepartment] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [startDate, setStartDate] = useState(new Date().getFullYear());

  const restaurants = api.owner.useCreationStats({
    department,
    zipCode,
    startDate,
    region,
  });

  const handleRegionChange = (event: SelectChangeEvent) => {
    setDepartment("");
    setZipcode("");
    setRegion(event.target.value as string);
  };
  const handleDepartmentChange = (event: SelectChangeEvent) => {
    setZipcode("");
    setRegion("france");
    setDepartment(event.target.value as string);
  };

  const columns = useMemo<TableColumn<AdminGetNewRestaurantsDto>[]>(
    () => [
      {
        name: "Période",
        selector: (row) => row.month_year ?? "Total de l'année",
        sortable: true,
        sortField: "date",
        maxWidth: "180px",
      },
      //   {
      //     name: "Région",
      //     selector: (row) => region,
      //     maxWidth: "80px",
      //   },
      {
        name: "Région",
        selector: (row) => (row.region == "idf" ? "Ile de France" : row.region),
        maxWidth: "280px",
      },
      {
        name: "Département",
        selector: (row) => row.department,
        maxWidth: "120px",
      },
      {
        name: "Code postal",
        selector: (row) => row.zipCode,
        maxWidth: "80px",
      },
      {
        name: "total créés",
        selector: (row) => row.count,
        maxWidth: "180px",
      },
    ],
    []
  );

  const getDepartments = (region: string) => {
    if (region === "france") return Departments;
    else if (region === "idf")
      return Departments.filter(
        (department) =>
          (department.num_dep >= 77 && department.num_dep < 79) ||
          (department.num_dep >= 91 && department.num_dep < 96) ||
          department.num_dep > 7500
      );
    else if (region === "province")
      return Departments.filter(
        (department) =>
          (department.num_dep < 75 || department.num_dep > 95) &&
          department.num_dep < 7500
      );
  };

  return (
    <BaseContainer>
      <Typography variant="h3" component="h1">
        Nombre de restaurants créés
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            columnGap: "1rem",
            alignItems: "center",
          }}
        >
          <InputLabel>Région</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Région"
            title="Région"
            onChange={handleRegionChange}
          >
            <MenuItem value={"france"}>France</MenuItem>
            <MenuItem value={"province"}>Province</MenuItem>
            <MenuItem value={"idf"}>Ile de France</MenuItem>
          </Select>
        </div>
        <h1
          style={{
            fontSize: "1.5rem",
          }}
        >
          Ou
        </h1>
        <div
          style={{
            display: "flex",
            columnGap: "1rem",
            alignItems: "center",
          }}
        >
          <InputLabel>Département</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Département"
            onChange={handleDepartmentChange}
            value={department}
          >
            {getDepartments(region).map((department) => (
              <MenuItem value={department.num_dep}>
                {department.dep_name + " (" + department.num_dep + ")"}
              </MenuItem>
            ))}
          </Select>
        </div>
        <h1
          style={{
            fontSize: "1.5rem",
          }}
        >
          Ou
        </h1>
        <Input
          value={zipCode}
          onChange={(e) => {
            setDepartment("");
            setRegion("france");
            setZipcode(e.target.value);
          }}
          placeholder="Code postal"
        />
        <h1
          style={{
            fontSize: "1.5rem",
          }}
        >
          Date
        </h1>
        <DatePicker
          views={["year"]}
          defaultValue={new Date().getFullYear()}
          value={startDate.toString()}
          onChange={(date) => setStartDate(date.getFullYear())}
          clearable
        />
      </div>

      <Box mt={3} />
      <DataTable
        persistTableHead
        // noDataComponent
        columns={columns}
        data={restaurants.data}
        responsive
        // onSort={onSortChanged}
        sortServer
        noContextMenu
        noHeader
        expandOnRowDoubleClicked
        selectableRowsHighlight
        highlightOnHover
        sortIcon={<ExpandMoreRounded />}
        // onRowClicked
        defaultSortAsc={defaultParams.sortDirection === "asc"}
        defaultSortFieldId={defaultParams.sortBy}
        // fixedHeader
        // fixedHeaderScrollHeight
        keyField="owner_id"
      />
    </BaseContainer>
  );
};
export default CreationStats;
