import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  List,
  CircularProgress,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  useFetchCreatorCodes,
  useDeleteCreatorCode,
} from "../../services/useCreateCreatorCode";
import DeleteIcon from "@material-ui/icons/Delete";

const CreatorCodeModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { data, isLoading } = useFetchCreatorCodes();
  const { mutate: deleteCreatorCode } = useDeleteCreatorCode();

  // Handle delete action with confirmation
  const handleDelete = (creatorCode: string) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer le code créateur "${creatorCode}" ?`
      )
    ) {
      deleteCreatorCode(creatorCode);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Codes Créateurs</DialogTitle>
      <DialogContent>
        <List>
          {data?.map((code) => (
            <ListItem
              key={code.creator_code}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <ListItemText
                primary={code.creator_code}
                secondary={code.is_used ? "Utilisé" : "Non utilisé"}
              />
              <IconButton
                onClick={() => handleDelete(code.creator_code)}
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatorCodeModal;
