//@ts-nocheck
import {
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import BaseContainer from "../../components/BaseContainer"
import { api, snackbarCatch } from "../../services/api"
import DataTable, { TableColumn } from "react-data-table-component"
import {
    GetMooverExchangeOrderAttributedDto,
    AdminGetNewRestaurantsSortByEnum,
    AdminGetNewRestaurantsSortDirectionEnum,
    GetVoucherDto,
} from "../../services/openapi"
import { AddRounded, DeleteRounded, ExpandMoreRounded } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import DateRangePicker from "../../components/DateRangePicker"

const defaultParams: {
    sortBy: string
    sortDirection: AdminGetNewRestaurantsSortDirectionEnum
    page: number
    limit: number
} = {
    sortBy: "created_at",
    sortDirection: "asc",
    page: 1,
    limit: 10,
}

const WheelScene = () => {
    const [page, setPage] = useState(defaultParams.page)
    const [limit, setLimit] = useState(defaultParams.limit)

    const [sorting, setSorting] = useState<{
        sortBy: string
        sortDirection: AdminGetNewRestaurantsSortDirectionEnum
    }>({
        sortBy: defaultParams.sortBy,
        sortDirection: defaultParams.sortDirection,
    })

    const savings = api.moover.useMooverExchangeOrderAttributed({
        page,
        limit,
        ...sorting,
    })

    const onSortChanged = (column: TableColumn<GetMooverExchangeOrderAttributedDto>, direction: "asc" | "desc") => {
        setSorting({
            sortBy: column.sortField!,
            sortDirection: direction,
        })
    }

    const columns = useMemo<TableColumn<GetMooverExchangeOrderAttributedDto>[]>(
        () => [
            {
                name: "Date",
                selector: (row) => format(new Date(row.year, row.month, 1), "LLLL yyyy", { locale: fr }),
                sortable: true,
                sortField: "date",
            },
            {
                name: "Montant début du mois",
                selector: (row) => row.beginning_amount,
            },
            {
                name: "Montant bon d'échange attribué",
                selector: (row) => row.attributed_amount,
            },
            {
                name: "Montant bon cadeaux attribué",
                selector: (row) => row.voucher_attributed_amount,
            },
            {
                name: "Montant fin de mois",
                selector: (row) => row.end_amount,
            },
        ],
        [],
    )

    if (savings.isLoading || savings.data === undefined) {
        return (
            <BaseContainer>
                <Typography variant="h3" component="h1">
                    Bons d'échange attribués - montant utilisés
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <CircularProgress />
                </Box>
            </BaseContainer>
        )
    }

    return (
        <BaseContainer>
            <Typography variant="h3" component="h1">
                Bons d'échange attribués - montant utilisés
            </Typography>

            <Box mt={3} />
            <DataTable
                persistTableHead
                // noDataComponent
                columns={columns}
                data={savings.data.data}
                responsive
                onSort={onSortChanged}
                sortServer
                noContextMenu
                noHeader
                expandOnRowDoubleClicked
                selectableRowsHighlight
                highlightOnHover
                sortIcon={<ExpandMoreRounded />}
                // onRowClicked
                paginationPerPage={savings.data.limit}
                paginationTotalRows={savings.data.totalCount}
                paginationRowsPerPageOptions={[5, 10, 20]}
                paginationDefaultPage={defaultParams.page}
                defaultSortAsc={defaultParams.sortDirection === "asc"}
                defaultSortFieldId={defaultParams.sortBy}
                onChangeRowsPerPage={setLimit}
                onChangePage={setPage}
                // fixedHeader
                // fixedHeaderScrollHeight
                keyField="owner_id"
            />
        </BaseContainer>
    )
}
export default WheelScene
