//@ts-nocheck
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Input,
  InputLabel,
} from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import BaseContainer from "../../components/BaseContainer";
import { api, snackbarCatch } from "../../services/api";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  AdminGetOwnerExpenseDto,
  AdminGetOwnerExpensesSortByEnum,
  AdminGetOwnerExpensesSortDirectionEnum,
} from "../../services/openapi";
import {
  AddRounded,
  DeleteRounded,
  ExpandMoreRounded,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { subWeeks } from "date-fns";
import DateRangePicker from "../../components/DateRangePicker";
import Departments from "../../departments.json";

const defaultParams: {
  page: number;
  limit: number;
  sortBy: AdminGetOwnerExpensesSortByEnum;
  sortDirection: AdminGetOwnerExpensesSortDirectionEnum;
} = {
  page: 1,
  limit: 10,
  sortBy: "restaurant_name",
  sortDirection: "asc",
};

const SponsoringScene = () => {
  const [page, setPage] = useState(defaultParams.page);
  const [limit, setLimit] = useState(defaultParams.limit);
  const [department, setDepartment] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [sorting, setSorting] = useState<{
    sortBy: AdminGetOwnerExpensesSortByEnum;
    sortDirection: AdminGetOwnerExpensesSortDirectionEnum;
  }>({
    sortBy: defaultParams.sortBy,
    sortDirection: defaultParams.sortDirection,
  });

  const onSortChanged = (
    column: TableColumn<AdminGetOwnerExpenseDto>,
    direction: "asc" | "desc"
  ) => {
    setSorting({
      sortBy: column.sortField! as AdminGetOwnerExpensesSortByEnum,
      sortDirection: direction,
    });
  };

  // TODO: auction max/min/avg
  // TODO: offer count/duration

  const [dateRange, setDateRange] = useState({
    startDate: subWeeks(new Date(), 1),
    endDate: new Date(),
  });

  const restaurants = api.owner.useOwnerExpenses({
    startDate: dateRange.startDate.toISOString(),
    endDate: dateRange.endDate.toISOString(),
    page,
    limit,
    ...sorting,
    department,
    zipCode,
  });
  const restaurantStats = api.owner.useOwnerStats({
    startDate: dateRange.startDate.toISOString(),
    endDate: dateRange.endDate.toISOString(),
  });

  const amounts = restaurants?.data?.data.map((result) => result.amount);

  const maxAmount = amounts && amounts.length > 0 ? Math.max(...amounts) : 0;
  const minAmount = amounts && amounts.length > 0 ? Math.min(...amounts) : 0;
  const avgAmount =
    amounts && amounts.length > 0
      ? amounts?.reduce((sum, amount) => sum + amount, 0) / amounts.length
      : 0;

  const columns = useMemo<TableColumn<AdminGetOwnerExpenseDto>[]>(
    () => [
      {
        name: "Date",
        selector: (row) => row.date_sponsored.split("T")[0],
        sortField: "date_sponsored",
        maxWidth: "80px",
      },
      {
        name: "Département",
        selector: (row) => row.department,
        maxWidth: "120px",
      },
      {
        name: "Code postal",
        selector: (row) => row.zipCode,
        maxWidth: "80px",
      },
      {
        name: "Nom du restaurant",
        selector: (row) => row.restaurant_name,
        sortField: "restaurant_name",
        sortable: true,
      },
      {
        name: "Prix du coup de coeur",
        selector: (row) => row.amount,
      },
    ],
    []
  );

  if (
    restaurants.isLoading ||
    restaurants.data === undefined ||
    restaurantStats.isLoading ||
    restaurantStats.data === undefined
  ) {
    return (
      <BaseContainer>
        <Typography variant="h3" component="h1">
          statistique sponsoring
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress />
        </Box>
      </BaseContainer>
    );
  }
  const handleDepartmentChange = (event: SelectChangeEvent) => {
    setZipcode("");
    setDepartment(event.target.value as string);
  };

  return (
    <BaseContainer>
      <Typography variant="h3" component="h1">
        Statistique sponsoring
      </Typography>

      <Box
        mt={3}
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: 80,
        }}
      >
        <div
          style={{
            display: "flex",
            columnGap: "1rem",
            alignItems: "center",
          }}
        >
          <InputLabel>Département</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Département"
            value={department}
            onChange={handleDepartmentChange}
          >
            {Departments.map((department) => (
              <MenuItem value={department.num_dep}>
                {department.dep_name + " (" + department.num_dep + ")"}
              </MenuItem>
            ))}
          </Select>
        </div>
        <h1
          style={{
            fontSize: "1.5rem",
          }}
        >
          Ou
        </h1>
        <Input
          value={zipCode}
          onChange={(e) => {
            setDepartment("");
            setZipcode(e.target.value);
          }}
          placeholder="Code postal"
        />
        <DateRangePicker value={dateRange} onChange={setDateRange} />
      </Box>

      <Box mt={3} display={"flex"} justifyContent="space-between">
        <Box width="80%">
          <DataTable
            persistTableHead
            // noDataComponent
            columns={columns}
            data={restaurants.data.data}
            responsive
            onSort={onSortChanged}
            sortServer
            noContextMenu
            noHeader
            expandOnRowDoubleClicked
            selectableRowsHighlight
            highlightOnHover
            sortIcon={<ExpandMoreRounded />}
            // onRowClicked
            pagination
            paginationServer
            paginationPerPage={restaurants.data.limit}
            paginationTotalRows={restaurants.data.totalCount}
            paginationRowsPerPageOptions={[5, 10, 20]}
            paginationDefaultPage={defaultParams.page}
            defaultSortAsc={defaultParams.sortDirection === "asc"}
            defaultSortFieldId={defaultParams.sortBy}
            onChangeRowsPerPage={setLimit}
            onChangePage={setPage}
            // fixedHeader
            // fixedHeaderScrollHeight
            keyField="owner_id"
          />
        </Box>
        <TableContainer
          component={Paper}
          style={{ width: "17%", height: "fit-content" }}
        >
          <Table aria-label="gender">
            <TableBody>
              <TableRow>
                <TableCell align="center">Nombre de jours</TableCell>
                <TableCell align="center">
                  {restaurants?.data.data.length}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center">plus basse enchère</TableCell>
                <TableCell align="center">{minAmount}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center">enchère moyenne</TableCell>
                <TableCell align="center">{avgAmount.toFixed(2)}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center">plus haute enchère</TableCell>
                <TableCell align="center">{maxAmount}</TableCell>
              </TableRow>
              {/* 
              <TableRow>
                <TableCell align="center">nombre offres</TableCell>
                <TableCell align="center">
                  {restaurantStats.data.offer_count}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="center">nombre jour parution</TableCell>
                <TableCell align="center">
                  {restaurantStats.data.day_count}
                </TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </BaseContainer>
  );
};
export default SponsoringScene;
