import { useMutation } from 'react-query';
import { AdminApi } from './openapi';
import { queryClient } from "..";
import { useQuery } from 'react-query';

export const useCreateCreatorCode = () => {
  return useMutation({
    mutationFn: AdminApi.adminCreateCreatorCode,
    onSuccess: () => {
      queryClient.invalidateQueries("createCreatorCode"); 
    },
    onError: (error) => {
      console.error('Error creating creator code:', error); // Handle error
    },
  });
};


export const useFetchCreatorCodes = () => {
  return useQuery(["fetchCreatorCodes"], () =>
    AdminApi.fetchCreatorCodes()
  );
};

export const useDeleteCreatorCode = () => {
  return useMutation({
    mutationFn: AdminApi.deleteCreatorCode,
    onSuccess: () => {
      queryClient.invalidateQueries("fetchCreatorCodes");
    },
    onError: (error) => {
      console.error("Error deleting creator code:", error);
    },
  });
};
