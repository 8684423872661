/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"

/**
 *
 */
export class DefaultApi /* extends runtime.BaseAPI */ {
    /**
     */
    static check(): Promise<void> {
        return rest.get(`/health`)
    }
}
