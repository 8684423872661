//@ts-nocheck
import { Box, Button, CircularProgress, Container, FormLabel, IconButton, Typography } from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import BaseContainer from "../../components/BaseContainer"
import { api, snackbarCatch } from "../../services/api"
import DataTable, { TableColumn } from "react-data-table-component"
import {
    AdminGetOwnerExchangeOrderDto,
    AdminGetVouchersRequest,
    AdminGetOwnerExchangeOrderSortByEnum,
    AdminGetOwnerExchangeOrderSortDirectionEnum,
} from "../../services/openapi"
import { AddRounded, DeleteRounded, ExpandMoreRounded } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { KeyboardDatePicker } from "@material-ui/pickers"

const defaultParams: {
    page: number
    limit: number
    sortBy: AdminGetOwnerExchangeOrderSortByEnum
    sortDirection: AdminGetOwnerExchangeOrderSortDirectionEnum
} = {
    page: 1,
    limit: 10,
    sortBy: "created_at",
    sortDirection: "asc",
}

const RestaurantExchangeOrderScene = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = useState(defaultParams.page)
    const [limit, setLimit] = useState(defaultParams.limit)
    const [sorting, setSorting] = useState<{
        sortBy: AdminGetOwnerExchangeOrderSortByEnum
        sortDirection: AdminGetOwnerExchangeOrderSortDirectionEnum
    }>({
        sortBy: defaultParams.sortBy,
        sortDirection: defaultParams.sortDirection,
    })
    const [period, setPeriod] = useState(new Date())
    const onSortChanged = (column: TableColumn<AdminGetOwnerExchangeOrderDto>, direction: "asc" | "desc") => {
        setSorting({
            sortBy: column.sortField! as AdminGetOwnerExchangeOrderSortByEnum,
            sortDirection: direction,
        })
    }

    const restaurants = api.owner.useOwnerExchangeOrder({
        page,
        limit,
        period: period.toISOString(),
        ...sorting,
    })

    const columns = useMemo<TableColumn<AdminGetOwnerExchangeOrderDto>[]>(
        () => [
            // {
            //     name: "Date",
            //     selector: (row) => row.,
            //     sortField: "",
            //     sortable: true,
            // },
            {
                name: "Nom de l'entreprise",
                selector: (row) => row.company_name,
            },
            {
                name: "Adresse",
                selector: (row) => row.address,
            },
            {
                name: "IBAN",
                selector: (row) => row.iban,
            },
            {
                name: "Montant bons d'échanges à rembourser",
                selector: (row) => row.amount_to_repay,
            },
            {
                name: "Abondement (50%)",
                selector: (row) => row.abondement,
            },
            {
                name: "Total à rembourser",
                selector: (row) => row.total_to_repay,
            },
        ],
        [],
    )

    if (restaurants.isLoading || restaurants.data === undefined) {
        return (
            <BaseContainer>
                <Typography variant="h3" component="h1">
                    Requêtes sur bons d'échange du mois précédent acceptés en paiement
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <CircularProgress />
                </Box>
            </BaseContainer>
        )
    }

    return (
        <BaseContainer>
            <Typography variant="h3" component="h1">
                Requêtes sur bons d'échange du mois précédent acceptés en paiement
            </Typography>

            <Box mt={3} mb={3} maxWidth={500}>
                <FormLabel htmlFor="start-date">Period</FormLabel>
                <Box>
                    <KeyboardDatePicker
                        id="start-date"
                        value={period}
                        format="MM/yyyy"
                        onChange={(start) => start && !isNaN(start.valueOf()) && setPeriod(start)}
                    />
                </Box>
            </Box>

            <DataTable
                persistTableHead
                // noDataComponent
                columns={columns}
                data={restaurants.data.data}
                responsive
                onSort={onSortChanged}
                sortServer
                noContextMenu
                noHeader
                expandOnRowDoubleClicked
                selectableRowsHighlight
                highlightOnHover
                sortIcon={<ExpandMoreRounded />}
                // onRowClicked
                pagination
                paginationServer
                paginationPerPage={restaurants.data.limit}
                paginationTotalRows={restaurants.data.totalCount}
                paginationRowsPerPageOptions={[5, 10, 20]}
                paginationDefaultPage={defaultParams.page}
                defaultSortAsc={defaultParams.sortDirection === "asc"}
                defaultSortFieldId={defaultParams.sortBy}
                onChangeRowsPerPage={setLimit}
                onChangePage={setPage}
                // fixedHeader
                // fixedHeaderScrollHeight
                keyField="restaurant_id"
            />
        </BaseContainer>
    )
}
export default RestaurantExchangeOrderScene
