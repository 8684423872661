//@ts-nocheck
import React from "react";
import { GetUserDto } from "./services/openapi";
import AppLayout from "./components/layout/AppLayout";
import { Switch, Route, Redirect } from "react-router-dom";
import { urls } from "./services/urls";
import DashboardScene from "./scenes/Users";
import VoucherScene from "./scenes/Voucher";

import PrivateNavBar from "./components/layout/PrivateNavbar";
import MooverExchangeOrderScene from "./scenes/ExchangeOrder/moovers";
import RestaurantExchangeOrderScene from "./scenes/ExchangeOrder/restaurants";
import AdScene from "./scenes/Ad";
import MooverScene from "./scenes/Moover";
import AmbassadorScene from "./scenes/Ambassador";
import NewRestaurantsScene from "./scenes/Restaurants";
import SponsoringScene from "./scenes/Sponsoring";
import AttributedScene from "./scenes/ExchangeOrder/attributed";
import CreationStats from "./scenes/CreationStats";
import ClientStats from "./scenes/ClientStats";
import JobOfferStats from "./scenes/JobOffers";
import WheelScene from "./scenes/ExchangeOrder/roue";

const PrivateApp = ({ user }: { user: GetUserDto }) => {
  return (
    <AppLayout navigation={<PrivateNavBar user={user} />}>
      <Switch>
        <Route exact path={urls.users} component={DashboardScene} />
        <Route exact path={urls.moover} component={MooverScene} />
        <Route exact path={urls.ambassador} component={AmbassadorScene} />
        <Route exact path={urls.ad} component={AdScene} />
        <Route exact path={urls.sponsoring} component={SponsoringScene} />
        <Route
          exact
          path={urls.exchangeOrder.moovers}
          component={MooverExchangeOrderScene}
        />
        <Route
          exact
          path={urls.exchangeOrder.restaurants}
          component={RestaurantExchangeOrderScene}
        />
        <Route exact path={urls.voucher} component={VoucherScene} />
        <Route exact path={urls.restaurant} component={NewRestaurantsScene} />
        <Route exact path={urls.statsCreation} component={CreationStats} />
        <Route exact path={urls.statsJobOffers} component={JobOfferStats} />
        <Route exact path={urls.statsClients} component={ClientStats} />
        <Route exact path={urls.statsSponsoring} component={SponsoringScene} />

        <Route
          exact
          path={urls.exchangeOrder.attributed}
          component={AttributedScene}
        />
                <Route
          exact
          path={urls.exchangeOrder.roue}
          component={WheelScene}
        />
        <Redirect to={urls.users} />
      </Switch>
    </AppLayout>
  );
};

export default PrivateApp;
