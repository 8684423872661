import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import React from "react"
import { useLocation } from "react-router-dom"

import { urls } from "../../services/urls"
import { LinkIcon } from "../LinkIcon"

const useStyles = makeStyles(() =>
    createStyles({
        sectionDesktop: {
            display: "flex",
            height: "62px",
        },
    }),
)

const PublicNavbar = (): JSX.Element => {
    return <></>
}

export default PublicNavbar
