//@ts-nocheck
import { Box, Button, CircularProgress, Container, IconButton, Tooltip, Typography } from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import BaseContainer from "../../components/BaseContainer"
import { api, snackbarCatch } from "../../services/api"
import DataTable, { TableColumn } from "react-data-table-component"
import {
    GetVoucherDto,
    AdminGetVouchersRequest,
    AdminGetVouchersSortByEnum,
    AdminGetVouchersSortDirectionEnum,
} from "../../services/openapi"
import { AddRounded, DeleteRounded, ExpandMoreRounded } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import CreateVoucherModal from "./CreateVoucherModal"
import { format } from "date-fns"
import { fr } from "date-fns/locale"

const defaultParams: {
    page: number
    limit: number
    sortBy: AdminGetVouchersSortByEnum
    sortDirection: AdminGetVouchersSortDirectionEnum
} = {
    page: 1,
    limit: 10,
    sortBy: "created_at",
    sortDirection: "asc",
}

const VoucherScene = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = useState(defaultParams.page)
    const [limit, setLimit] = useState(defaultParams.limit)
    const [sorting, setSorting] = useState<{
        sortBy: AdminGetVouchersSortByEnum
        sortDirection: AdminGetVouchersSortDirectionEnum
    }>({
        sortBy: defaultParams.sortBy,
        sortDirection: defaultParams.sortDirection,
    })

    const onSortChanged = (column: TableColumn<GetVoucherDto>, direction: "asc" | "desc") => {
        setSorting({
            sortBy: column.sortField! as AdminGetVouchersSortByEnum,
            sortDirection: direction,
        })
    }

    const vouchers = api.voucher.useVouchers({
        page,
        limit,
        ...sorting,
    })

    const { mutateAsync: deleteVoucherMutation } = api.voucher.useDeleteVoucher()

    const deleteVoucher = useCallback(
        async (code: string) => {
            try {
                await deleteVoucherMutation({
                    code,
                })
            } catch (e) {
                snackbarCatch(e, enqueueSnackbar)
            }
        },
        [deleteVoucherMutation],
    )

    const [isModalOpen, setIsModalOpen] = useState(false)

    const columns = useMemo<TableColumn<GetVoucherDto>[]>(
        () => [
            {
                name: "Code",
                selector: (row) => row.code,
                sortField: "code",
                sortable: true,
            },
            {
                name: "Quantité",
                selector: (row) => `${row.amount}€`,
                sortField: "amount",
                sortable: true,
            },
            {
                name: "Créé le",
                selector: (row) => format(new Date(row.created_at), "d MMM yyyy", { locale: fr }),
                sortField: "created_at",
                sortable: true,
            },
            {
                name: "Créé par",
                selector: (row) => row.created_by_username ?? "(DELETED)",
            },
            {
                name: "Action",
                cell: function VoucherAction(row) {
                    return (
                        <Tooltip title={<Typography>Supprimer</Typography>}>
                            <IconButton
                                onClick={() => deleteVoucher(row.code)}
                                disabled={row.redeemed_by_username !== null}
                            >
                                <DeleteRounded />
                            </IconButton>
                        </Tooltip>
                    )
                },
            },
        ],
        [],
    )

    if (vouchers.isLoading || vouchers.data === undefined) {
        return (
            <BaseContainer>
                <Typography variant="h3" component="h1">
                    Bons cadeaux
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <CircularProgress />
                </Box>
            </BaseContainer>
        )
    }

    return (
        <BaseContainer>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h3" component="h1">
                    Bons cadeaux
                </Typography>
                <Button onClick={() => setIsModalOpen(true)} startIcon={<AddRounded />} variant="contained">
                    Créer
                </Button>
            </Box>

            <CreateVoucherModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

            <Box mt={3} />

            <DataTable
                persistTableHead
                // noDataComponent
                columns={columns}
                data={vouchers.data.data}
                responsive
                onSort={onSortChanged}
                sortServer
                noContextMenu
                noHeader
                expandOnRowDoubleClicked
                selectableRowsHighlight
                highlightOnHover
                sortIcon={<ExpandMoreRounded />}
                selectableRowsComponentProps={{ color: "primary" }}
                // onRowClicked
                pagination
                paginationServer
                paginationPerPage={vouchers.data.limit}
                paginationTotalRows={vouchers.data.totalCount}
                paginationRowsPerPageOptions={[5, 10, 20]}
                paginationDefaultPage={defaultParams.page}
                defaultSortAsc={defaultParams.sortDirection === "asc"}
                defaultSortFieldId={defaultParams.sortBy}
                onChangeRowsPerPage={setLimit}
                onChangePage={setPage}
                // fixedHeader
                // fixedHeaderScrollHeight
                keyField="code"
            />
        </BaseContainer>
    )
}
export default VoucherScene
