/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
    CreateCommentDto,
    CreateCommentResponseDto,
    GetMyAmbassadorProfileDto,
    IsAmbassadorProfileInDraftDto,
    MyAmbassadorProfileCommentDto,
} from "../models"

export interface CreateCommentRequest {
    restaurantId: string
    createCommentDto: CreateCommentDto
}

export interface GetProfileCommentRequest {
    page: number
    limit: number
    sortBy?: GetProfileCommentSortByEnum
    sortDirection?: GetProfileCommentSortDirectionEnum
}

export interface ServeProfilePictureRequest {
    filename: string
}

export interface UpdateAmbassadorProfileRequest {
    facebookAccount: string
    instagramAccount: string
    youtubeAccount: string
    tiktokAccount: string
    twitchAccount: string
    profile: Blob
    username: string
    description: string
}

export interface UpdateCommentRequest {
    commentId: string
    createCommentDto: CreateCommentDto
}

/**
 *
 */
export class AmbassadorsApi /* extends runtime.BaseAPI */ {
    /**
     */
    static createComment({ createCommentDto, restaurantId }: CreateCommentRequest): Promise<CreateCommentResponseDto> {
        return rest.post(`/ambassadors/restaurants/${encodeURIComponent(restaurantId)}/comment`, createCommentDto)
    }

    /**
     */
    static getMyProfile(): Promise<GetMyAmbassadorProfileDto> {
        return rest.get(`/ambassadors/public-profile`)
    }

    /**
     */
    static getProfileComment({
        ...queryParameters
    }: GetProfileCommentRequest): Promise<Array<MyAmbassadorProfileCommentDto>> {
        return rest.get(`/ambassadors/public-profile/comments?${rest.encodeObjectToUrl(queryParameters)}`)
    }

    /**
     */
    static isAmbassadorProfileInDraft(): Promise<IsAmbassadorProfileInDraftDto> {
        return rest.get(`/ambassadors/public-profile/is-draft`)
    }

    /**
     */
    static serveProfilePicture({ filename }: ServeProfilePictureRequest): Promise<void> {
        return rest.get(`/ambassadors/profile-pictures/${encodeURIComponent(filename)}`)
    }

    /**
     */
    static updateAmbassadorProfile({
        facebookAccount,
        instagramAccount,
        youtubeAccount,
        tiktokAccount,
        twitchAccount,
        profile,
        username,
        description,
    }: UpdateAmbassadorProfileRequest): Promise<void> {
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }]
        const canConsumeForm = runtime.canConsumeForm(consumes)

        let formParams: { append(param: string, value: any): any }
        let useForm = false
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm
        if (useForm) {
            formParams = new FormData()
        } else {
            formParams = new URLSearchParams()
        }

        if (facebookAccount !== undefined) {
            formParams.append("facebook_account", facebookAccount)
        }
        if (instagramAccount !== undefined) {
            formParams.append("instagram_account", instagramAccount)
        }
        if (youtubeAccount !== undefined) {
            formParams.append("youtube_account", youtubeAccount)
        }
        if (tiktokAccount !== undefined) {
            formParams.append("tiktok_account", tiktokAccount)
        }
        if (twitchAccount !== undefined) {
            formParams.append("twitch_account", twitchAccount)
        }
        if (profile !== undefined) {
            formParams.append("profile", profile)
        }
        if (username !== undefined) {
            formParams.append("username", username)
        }
        if (description !== undefined) {
            formParams.append("description", description)
        }
        return rest.formData(`/ambassadors/public-profile`, formParams, "put", true)
    }

    /**
     */
    static updateComment({ createCommentDto, commentId }: UpdateCommentRequest): Promise<void> {
        return rest.put(`/ambassadors/restaurants/comments/${encodeURIComponent(commentId)}`, createCommentDto, true)
    }
}

/**
 * @export
 * @enum {string}
 */
export type GetProfileCommentSortByEnum = "created_at" | "like_count"
/**
 * @export
 * @enum {string}
 */
export type GetProfileCommentSortDirectionEnum = "asc" | "desc"
