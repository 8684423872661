import { BASE_API_URL } from "./constants"

/* eslint-disable @typescript-eslint/no-explicit-any */

type UrlEncodableObject = {
    [key: string]: Array<string | number | boolean> | string | number | boolean | undefined
}
export function encodeObjectToUrl(o: object): string {
    const obj = o as UrlEncodableObject
    const params = []
    for (const key in obj) {
        const value = obj[key]
        if (Array.isArray(value)) {
            if (value.length > 0) params.push(key + "=" + encodeURIComponent(value.join(",")))
        } else if (value !== undefined) {
            params.push(key + "=" + encodeURIComponent(value))
        }
    }
    return params.join("&")
}

const customFetch = async <T>(
    endpoint: string,
    {
        headers = { "Content-Type": "application/json", "Accept": "application/json, text/plain, */*" },
        ...options
    }: RequestInit,
    ignoreResult: boolean,
): Promise<T> => {
    const response = await fetch(`${BASE_API_URL}${endpoint}`, {
        ...options,
        headers,
        credentials: "include",
    })

    if (!response.ok) {
        throw await response.json()
    }
    if (ignoreResult) {
        return undefined as unknown as T
    } else {
        return response.json()
    }
}

const rest = {
    encodeObjectToUrl,

    get: <TResult>(endpoint: string): Promise<TResult> => customFetch(endpoint, { method: "GET" }, false),

    post: <TResult, TIgnore extends boolean | undefined = undefined>(
        endpoint: string,
        data: unknown = {},
        ignoreResult?: TIgnore,
    ): Promise<TIgnore extends true ? void : TResult> =>
        customFetch(endpoint, { method: "POST", body: JSON.stringify(data) }, ignoreResult ?? false),

    patch: <TResult, TIgnore extends boolean | undefined = undefined>(
        endpoint: string,
        data: unknown = {},
        ignoreResult?: TIgnore,
    ): Promise<TIgnore extends true ? void : TResult> =>
        customFetch(endpoint, { method: "PATCH", body: JSON.stringify(data) }, ignoreResult ?? false),

    put: <TResult, TIgnore extends boolean | undefined = undefined>(
        endpoint: string,
        data: unknown = {},
        ignoreResult?: TIgnore,
    ): Promise<TIgnore extends true ? void : TResult> =>
        customFetch(endpoint, { method: "PUT", body: JSON.stringify(data) }, ignoreResult ?? false),

    delete: (endpoint: string, data: unknown | undefined): Promise<void> =>
        customFetch(
            endpoint,
            data === undefined ? { method: "DELETE" } : { method: "DELETE", body: JSON.stringify(data) },
            true,
        ),

    formData: <TResult, TIgnore extends boolean | undefined = undefined>(
        endpoint: string,
        data: { append(param: string, value: any): any },
        method: "post" | "put" | "patch",
        ignoreResult?: TIgnore,
    ): Promise<TIgnore extends true ? void : TResult> =>
        customFetch(
            endpoint,
            {
                method,
                body: data as FormData | URLSearchParams,
                headers: { Accept: "application/json, text/plain, */*" },
            },
            ignoreResult ?? false,
        ),
}

export default rest
