//@ts-nocheck
import React from "react"
import { Box, CircularProgress } from "@material-ui/core"
import { GetUserDto } from "./services/openapi"
import AppLayout from "./components/layout/AppLayout"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import { urls } from "./services/urls"
import LoginScene from "./scenes/Login"
import PublicNavbar from "./components/layout/PublicNavbar"

const PublicApp = () => {
    return (
        <AppLayout navigation={<PublicNavbar />}>
            <Switch>
                <Route exact path={urls.account.login} component={LoginScene} />
                <Redirect to={urls.account.login} />
            </Switch>
        </AppLayout>
    )
}

export default PublicApp
