/* tslint:disable */
/* eslint-disable */

import * as runtime from "../runtime"
import rest from "../../http"
import {
    AmountRetrocessingQrCodeDto,
    CreateMooverRetrocessingQRCodeResponseDto,
    CreateMooverSavingQRCodeResponseDto,
    GetAmbassadorsDto,
    GetFavoriteRestaurantsDto,
    GetMoovDetailsDto,
    GetMooverPersonalInformationDto,
    GetMooverProfileDto,
    GetOneCommentDto,
    GetOneRestaurant,
    GetRedeemedVouchersDto,
    GetRestaurantCommentsDto,
    GetRestaurantsDto,
    GetSavingsDto,
    GetTotalSavingStatsDto,
    MooverAppLaunchDto,
    RedeemVoucherDto,
    SetSavingDto,
    UpdateMooverProfileDto,
} from "../models"

export interface AppLaunchRequest {
    mooverAppLaunchDto: MooverAppLaunchDto
}

export interface FollowAmbassadorRequest {
    ambassadorId: string
}

export interface GetAmbassadorProfilesRequest {
    page: number
    limit: number
    queryName?: string
    sortDirection?: GetAmbassadorProfilesSortDirectionEnum
}

export interface GetFavoriteRestaurantsRequest {
    zipcode: string
}

export interface GetOneAmbassadorProfileRequest {
    ambassadorId: string
}

export interface GetOneCommentRequest {
    commentId: string
}

export interface GetRealizedSavingsRequest {
    page: number
    limit: number
    sortBy?: GetRealizedSavingsSortByEnum
    sortDirection?: GetRealizedSavingsSortDirectionEnum
}

export interface GetRestaurantRequest {
    restaurantId: string
}

export interface GetRestaurantCommentsRequest {
    restaurantId: string
    page: number
    limit: number
    sortBy?: GetRestaurantCommentsSortByEnum
    sortDirection?: GetRestaurantCommentsSortDirectionEnum
}

export interface GetRestaurantsRequest {
    page: number
    limit: number
    locationPointLng?: number
    locationPointLat?: number
    queryName?: string
    sortBy?: GetRestaurantsSortByEnum
    sortDirection?: GetRestaurantsSortDirectionEnum
}

export interface GetSavingRequest {
    savingId: string
}

export interface LikeAmbassadorCommentRequest {
    commentId: string
}

export interface RedeemVoucherRequest {
    redeemVoucherDto: RedeemVoucherDto
}

export interface RetrocessingQrCodeForRestaurantRequest {
    amountRetrocessingQrCodeDto: AmountRetrocessingQrCodeDto
}

export interface SavingQrCodeForRestaurantRequest {
    restaurantId: string
}

export interface SetSavingRequest {
    savingId: string
    setSavingDto: SetSavingDto
}

export interface UnfollowAmbassadorRequest {
    ambassadorId: string
}

export interface UnlikeAmbassadorCommentRequest {
    commentId: string
}

export interface UpdateMooverProfileRequest {
    updateMooverProfileDto: UpdateMooverProfileDto
}

/**
 *
 */
export class MooversApi /* extends runtime.BaseAPI */ {
    /**
     */
    static appLaunch({ mooverAppLaunchDto }: AppLaunchRequest): Promise<void> {
        return rest.post(`/moovers/app-launch`, mooverAppLaunchDto, true)
    }

    /**
     * Unfollow an ambassador
     */
    static followAmbassador({ ambassadorId }: FollowAmbassadorRequest): Promise<void> {
        return rest.post(`/moovers/ambassadors/${encodeURIComponent(ambassadorId)}/follow`, undefined, true)
    }

    /**
     * Get many ambassador profiles
     */
    static getAmbassadorProfiles({
        ...queryParameters
    }: GetAmbassadorProfilesRequest): Promise<Array<GetAmbassadorsDto>> {
        return rest.get(`/moovers/ambassadors?${rest.encodeObjectToUrl(queryParameters)}`)
    }

    /**
     * Get favorite restaurants
     */
    static getFavoriteRestaurants({
        ...queryParameters
    }: GetFavoriteRestaurantsRequest): Promise<GetFavoriteRestaurantsDto> {
        return rest.get(`/moovers/restaurants/favorite?${rest.encodeObjectToUrl(queryParameters)}`)
    }

    /**
     */
    static getMoovDetails(): Promise<GetMoovDetailsDto> {
        return rest.get(`/moovers/details`)
    }

    /**
     * Get peronal informations
     */
    static getMooverProfile(): Promise<GetMooverProfileDto> {
        return rest.get(`/moovers/profile`)
    }

    /**
     * Get an ambassador\'s profile
     */
    static getOneAmbassadorProfile({ ambassadorId }: GetOneAmbassadorProfileRequest): Promise<GetAmbassadorsDto> {
        return rest.get(`/moovers/ambassadors/${encodeURIComponent(ambassadorId)}`)
    }

    /**
     */
    static getOneComment({ commentId }: GetOneCommentRequest): Promise<GetOneCommentDto> {
        return rest.get(`/moovers/restaurants/comments/${encodeURIComponent(commentId)}`)
    }

    /**
     * Get personal information
     */
    static getPersonalInformation(): Promise<GetMooverPersonalInformationDto> {
        return rest.get(`/moovers/personnal-information`)
    }

    /**
     */
    static getRealizedSavings({ ...queryParameters }: GetRealizedSavingsRequest): Promise<Array<GetSavingsDto>> {
        return rest.get(`/moovers/savings/realized?${rest.encodeObjectToUrl(queryParameters)}`)
    }

    /**
     * Get one restaurant by id
     */
    static getRestaurant({ restaurantId }: GetRestaurantRequest): Promise<GetOneRestaurant> {
        return rest.get(`/moovers/restaurants/${encodeURIComponent(restaurantId)}`)
    }

    /**
     * Get restaurant comments
     */
    static getRestaurantComments({
        restaurantId,
        ...queryParameters
    }: GetRestaurantCommentsRequest): Promise<Array<GetRestaurantCommentsDto>> {
        return rest.get(
            `/moovers/restaurants/${encodeURIComponent(restaurantId)}/comments?${rest.encodeObjectToUrl(
                queryParameters,
            )}`,
        )
    }

    /**
     * Get restaurants
     */
    static getRestaurants({ ...queryParameters }: GetRestaurantsRequest): Promise<Array<GetRestaurantsDto>> {
        return rest.get(`/moovers/restaurants?${rest.encodeObjectToUrl(queryParameters)}`)
    }

    /**
     * Get a saving to check if it has been validated
     */
    static getSaving({ savingId }: GetSavingRequest): Promise<GetSavingsDto> {
        return rest.get(`/moovers/savings/${encodeURIComponent(savingId)}`)
    }

    /**
     */
    static getSavingStats(): Promise<GetTotalSavingStatsDto> {
        return rest.get(`/moovers/savings/total`)
    }

    /**
     * Get redeemed vouchers
     */
    static getVouchers(): Promise<Array<GetRedeemedVouchersDto>> {
        return rest.get(`/moovers/vouchers`)
    }

    /**
     * Like a comment
     */
    static likeAmbassadorComment({ commentId }: LikeAmbassadorCommentRequest): Promise<void> {
        return rest.post(`/moovers/comments/${encodeURIComponent(commentId)}/like`, undefined, true)
    }

    /**
     * Redeem a voucher
     */
    static redeemVoucher({ redeemVoucherDto }: RedeemVoucherRequest): Promise<void> {
        return rest.post(`/moovers/vouchers`, redeemVoucherDto, true)
    }

    /**
     * Generate a QR code from retrocession for a restaurant
     */
    static retrocessingQrCodeForRestaurant({
        amountRetrocessingQrCodeDto,
    }: RetrocessingQrCodeForRestaurantRequest): Promise<CreateMooverRetrocessingQRCodeResponseDto> {
        return rest.post(`/moovers/retrocessing/qrcode`, amountRetrocessingQrCodeDto)
    }

    /**
     * Generate a QR code for a restaurant
     */
    static savingQrCodeForRestaurant({
        restaurantId,
    }: SavingQrCodeForRestaurantRequest): Promise<CreateMooverSavingQRCodeResponseDto> {
        return rest.post(`/moovers/restaurants/${encodeURIComponent(restaurantId)}/qrcode/saving`, undefined)
    }

    /**
     * Update rating and saved amount
     */
    static setSaving({ setSavingDto, savingId }: SetSavingRequest): Promise<void> {
        return rest.put(`/moovers/savings/${encodeURIComponent(savingId)}`, setSavingDto, true)
    }

    /**
     * Follow an ambassador
     */
    static unfollowAmbassador({ ambassadorId }: UnfollowAmbassadorRequest): Promise<void> {
        return rest.delete(`/moovers/ambassadors/${encodeURIComponent(ambassadorId)}/follow`, undefined)
    }

    /**
     * Unlike a comment
     */
    static unlikeAmbassadorComment({ commentId }: UnlikeAmbassadorCommentRequest): Promise<void> {
        return rest.delete(`/moovers/comments/${encodeURIComponent(commentId)}/like`, undefined)
    }

    /**
     * Update personal information
     */
    static updateMooverProfile({ updateMooverProfileDto }: UpdateMooverProfileRequest): Promise<void> {
        return rest.put(`/moovers/profile`, updateMooverProfileDto, true)
    }
}

/**
 * @export
 * @enum {string}
 */
export type GetAmbassadorProfilesSortDirectionEnum = "asc" | "desc"
/**
 * @export
 * @enum {string}
 */
export type GetRealizedSavingsSortByEnum = "created_at" | "rating" | "discount" | "discount_percent"
/**
 * @export
 * @enum {string}
 */
export type GetRealizedSavingsSortDirectionEnum = "asc" | "desc"
/**
 * @export
 * @enum {string}
 */
export type GetRestaurantCommentsSortByEnum = "created_at" | "like_count"
/**
 * @export
 * @enum {string}
 */
export type GetRestaurantCommentsSortDirectionEnum = "asc" | "desc"
/**
 * @export
 * @enum {string}
 */
export type GetRestaurantsSortByEnum = "estimated_discount_percent" | "distance" | "rank" | "last_visit"
/**
 * @export
 * @enum {string}
 */
export type GetRestaurantsSortDirectionEnum = "asc" | "desc"
