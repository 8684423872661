//@ts-nocheck
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { api } from "./services/api";
import { Box, CircularProgress } from "@material-ui/core";
import PrivateApp from "./PrivateApp";
import PublicApp from "./PublicApp";
import AppLayout from "./components/layout/AppLayout";

function LoginGate() {
  const user = api.auth.useUser();
  console.log("user", user);

  if (user.isLoading) {
    return (
      <AppLayout>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </AppLayout>
    );
  }

  if (user.data === undefined) {
    return <PublicApp />;
  }

  return <PrivateApp user={user.data} />;
}

export default LoginGate;
