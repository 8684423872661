//@ts-nocheck
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Input,
  InputLabel,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import BaseContainer from "../../components/BaseContainer";
import { api } from "../../services/api";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  AdminGetNewRestaurantsDto,
  AdminGetNewRestaurantsSortByEnum,
  AdminGetNewRestaurantsSortDirectionEnum,
} from "../../services/openapi";
import { ExpandMoreRounded } from "@material-ui/icons";
import Departments from "../../departments.json";
import { DatePicker } from "@material-ui/pickers";

const defaultParams: {
  sortBy: AdminGetNewRestaurantsSortByEnum;
  sortDirection: AdminGetNewRestaurantsSortDirectionEnum;
} = {
  sortBy: "created_at",
  sortDirection: "asc",
};

const ClientStats = () => {
  const [region, setRegion] = useState("france");
  const [department, setDepartment] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const restaurants = api.owner.useClientStats({
    department,
    zipCode,
    startDate,
    endDate,
  });

  const handleRegionChange = (event: SelectChangeEvent) => {
    setDepartment("");
    setZipcode("");
    setRegion(event.target.value as string);
  };
  const handleDepartmentChange = (event: SelectChangeEvent) => {
    setZipcode("");
    setDepartment(event.target.value as string);
  };

  const columns = useMemo<TableColumn<AdminGetNewRestaurantsDto>[]>(
    () => [
      {
        name: "NB/Nom de restaurants",
        selector: (row) => row.restaurantsCount ?? row.name,
        sortable: true,
        sortField: "date",
        maxWidth: "280px",
      },
      {
        name: "Département",
        selector: (row) => row.department,
        maxWidth: "110px",
      },
      {
        name: "Code postal",
        selector: (row) => row.zipCode,
        maxWidth: "80px",
      },

      {
        name: "Nb scans réductions",
        selector: (row) => row.scannedRestauratsCount,
        maxWidth: "150px",
      },
      {
        name: "Nb scans bons",
        selector: (row) => row.scannedSavingsCount,
        maxWidth: "150px",
      },

      {
        name: "Date de début",
        selector: (row) => row.startDate,
        maxWidth: "110px",
      },
      {
        name: "Date de fin",
        selector: (row) => row.endDate,
        maxWidth: "80px",
      },
      {
        name: "Nb restaurants scannés",
        selector: (row) => row.totalCount,
        maxWidth: "200px",
      },
    ],
    []
  );

  const getDepartments = (region: string) => {
    if (region === "france") return Departments;
    else if (region === "idf")
      return Departments.filter(
        (department) =>
          (department.num_dep >= 77 && department.num_dep < 79) ||
          (department.num_dep >= 91 && department.num_dep < 96) ||
          department.num_dep > 7500
      );
    else if (region === "province")
      return Departments.filter(
        (department) =>
          (department.num_dep < 75 || department.num_dep > 95) &&
          department.num_dep < 7500
      );
  };

  return (
    <BaseContainer>
      <Typography variant="h3" component="h1">
        Nombre de clients par restaurant
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            columnGap: "1rem",
            alignItems: "center",
          }}
        >
          <InputLabel>Région</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Région"
            title="Région"
            onChange={handleRegionChange}
          >
            <MenuItem value={"france"}>France</MenuItem>
            <MenuItem value={"province"}>Province</MenuItem>
            <MenuItem value={"idf"}>Ile de France</MenuItem>
          </Select>
        </div>
        <h1
          style={{
            fontSize: "1.5rem",
          }}
        >
          Et
        </h1>
        <div
          style={{
            display: "flex",
            columnGap: "1rem",
            alignItems: "center",
          }}
        >
          <InputLabel>Département</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Département"
            onChange={handleDepartmentChange}
            value={department}
          >
            {getDepartments(region).map((department) => (
              <MenuItem value={department.num_dep}>
                {department.dep_name + " (" + department.num_dep + ")"}
              </MenuItem>
            ))}
          </Select>
        </div>
        <h1
          style={{
            fontSize: "1.5rem",
          }}
        >
          Ou
        </h1>
        <Input
          value={zipCode}
          onChange={(e) => {
            setDepartment("");
            setZipcode(e.target.value);
          }}
          placeholder="Code postal"
        />
        <h1
          style={{
            fontSize: "1.5rem",
          }}
        >
          Début
        </h1>
        <DatePicker
          value={startDate}
          onChange={(date) => setStartDate(date)}
          clearable
        />
        <h1
          style={{
            fontSize: "1.5rem",
          }}
        >
          Fin
        </h1>
        <DatePicker
          value={endDate}
          onChange={(date) => setEndDate(date)}
          clearable
        />

        {/* <Button type="submit" variant="outlined" onClick={() => handleClick()}>
          Valider
        </Button> */}
      </div>

      <Box mt={3} />
      <DataTable
        persistTableHead
        // noDataComponent
        columns={columns}
        data={restaurants.data}
        responsive
        // onSort={onSortChanged}
        sortServer
        noContextMenu
        noHeader
        expandOnRowDoubleClicked
        selectableRowsHighlight
        highlightOnHover
        sortIcon={<ExpandMoreRounded />}
        // onRowClicked
        defaultSortAsc={defaultParams.sortDirection === "asc"}
        defaultSortFieldId={defaultParams.sortBy}
        // fixedHeader
        // fixedHeaderScrollHeight
        keyField="owner_id"
      />
    </BaseContainer>
  );
};
export default ClientStats;
