//@ts-nocheck
import { Box, BoxProps, makeStyles, Modal, Paper, Theme, Typography } from "@material-ui/core"
import React from "react"

interface Props extends BoxProps {
    isOpen: boolean
    onClose: () => void
    title?: string
    inlineContainer?: BoxProps
}

const useStyles = makeStyles((theme: Theme) => ({
    outlineContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        margin: "0 auto",
        outline: "none",
        border: "none",
    },
    inlineContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    header: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
}))

const BaseModal = ({ isOpen, onClose, children, title = "", inlineContainer = {}, ...props }: Props): JSX.Element => {
    const classes = useStyles()

    return (
        <Modal open={isOpen} onClose={onClose} className={classes.outlineContainer}>
            <Box component={Paper} className={classes.container} {...props}>
                <Box className={classes.header} display="flex" justifyContent="space-between" alignItems="flex-end">
                    <Typography variant="h4">{title}</Typography>
                </Box>
                <Box className={classes.inlineContainer} {...inlineContainer}>
                    {children}
                </Box>
            </Box>
        </Modal>
    )
}

export default BaseModal
