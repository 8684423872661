//@ts-nocheck
import {
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import BaseContainer from "../../components/BaseContainer"
import { api, snackbarCatch } from "../../services/api"
import DataTable, { TableColumn } from "react-data-table-component"
import {
    AdminGetNewRestaurantsDto,
    AdminGetNewRestaurantsSortByEnum,
    AdminGetNewRestaurantsSortDirectionEnum,
    GetVoucherDto,
} from "../../services/openapi"
import { AddRounded, DeleteRounded, ExpandMoreRounded } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import DateRangePicker from "../../components/DateRangePicker"
import dayjs from "dayjs"

const defaultParams: {
    sortBy: AdminGetNewRestaurantsSortByEnum
    sortDirection: AdminGetNewRestaurantsSortDirectionEnum
} = {
    sortBy: "created_at",
    sortDirection: "asc",
}

const NewRestaurantsScene = () => {
    const [dateRange, setDateRange] = useState({
        endDate: dayjs().startOf("month"),
    })

    const [sorting, setSorting] = useState<{
        sortBy: AdminGetNewRestaurantsSortByEnum
        sortDirection: AdminGetNewRestaurantsSortDirectionEnum
    }>({
        sortBy: defaultParams.sortBy,
        sortDirection: defaultParams.sortDirection,
    })

    const restaurants = api.owner.useNewRestaurants({
        ...sorting,
        endDate: dateRange.endDate.toISOString(),
    })

    const onSortChanged = (column: TableColumn<AdminGetNewRestaurantsDto>, direction: "asc" | "desc") => {
        setSorting({
            sortBy: column.sortField! as AdminGetNewRestaurantsSortByEnum,
            sortDirection: direction,
        })
    }

    const columns = useMemo<TableColumn<AdminGetNewRestaurantsDto>[]>(
        () => [
            {
                name: "Date",
                selector: (row) => format(new Date(row.year, row.month, 1), "LLLL yyyy", { locale: fr }),
                sortable: true,
                sortField: "date",
            },
            {
                name: "75",
                selector: (row) => row.d75,
                maxWidth: "80px",
            },
            {
                name: "78",
                selector: (row) => row.d78,
                maxWidth: "80px",
            },
            {
                name: "91",
                selector: (row) => row.d91,
                maxWidth: "80px",
            },
            {
                name: "92",
                selector: (row) => row.d92,
                maxWidth: "80px",
            },
            {
                name: "93",
                selector: (row) => row.d93,
                maxWidth: "80px",
            },
            {
                name: "94",
                selector: (row) => row.d94,
                maxWidth: "80px",
            },
            {
                name: "95",
                selector: (row) => row.d95,
                maxWidth: "80px",
            },
            {
                name: "Total ILE-DE-France",
                selector: (row) => row.d_idf,
            },
            {
                name: "Province",
                selector: (row) => row.d_province,
                maxWidth: "80px",
            },
            {
                name: "France ",
                selector: (row) => row.total,
                maxWidth: "80px",
            },
        ],
        [],
    )

    if (restaurants.isLoading || restaurants.data === undefined) {
        return (
            <BaseContainer>
                <Typography variant="h3" component="h1">
                    Nouveaux restaurateurs
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <CircularProgress />
                </Box>
            </BaseContainer>
        )
    }

    return (
        <BaseContainer>
            <Typography variant="h3" component="h1">
                Nouveaux restaurateurs
            </Typography>

            <Box mt={3} />
            <DataTable
                persistTableHead
                // noDataComponent
                columns={columns}
                data={restaurants.data}
                responsive
                onSort={onSortChanged}
                sortServer
                noContextMenu
                noHeader
                expandOnRowDoubleClicked
                selectableRowsHighlight
                highlightOnHover
                sortIcon={<ExpandMoreRounded />}
                // onRowClicked
                defaultSortAsc={defaultParams.sortDirection === "asc"}
                defaultSortFieldId={defaultParams.sortBy}
                // fixedHeader
                // fixedHeaderScrollHeight
                keyField="owner_id"
            />
        </BaseContainer>
    )
}
export default NewRestaurantsScene
