//@ts-nocheck
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import BaseContainer from "../../components/BaseContainer";
import { api, snackbarCatch } from "../../services/api";
import DataTable, { TableColumn } from "react-data-table-component";
import { GetVoucherDto, MooverStatsLocation } from "../../services/openapi";
import {
  AddRounded,
  DeleteRounded,
  ExpandMoreRounded,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { subWeeks } from "date-fns";
import DateRangePicker from "../../components/DateRangePicker";

const MooverScene = () => {
  const [dateRange, setDateRange] = useState({
    startDate: subWeeks(new Date(), 1),
    endDate: new Date(),
  });
  const moovers = api.moover.useMooverStats({
    startDate: dateRange.startDate.toISOString(),
    endDate: dateRange.endDate.toISOString(),
  });

  const idf = [75, 78, 91, 92, 93, 94, 95];
  const columns = useMemo<TableColumn<MooverStatsLocation>[]>(
    () => [
      ...idf
        .map((d) => {
          const dep = moovers.data?.department_count.find(
            (dp) => dp.department === d
          );
          if (!dep) return undefined;
          return {
            name: dep.department.toString(),
            selector: () => dep.count.toString(),
          };
        })
        .filter((x): x is Exclude<typeof x, undefined> => x !== undefined),
      {
        name: "Total ILE-DE-France",
        width: "150px",
        selector: () => moovers.data?.total_idf.toString() ?? "",
      },
      ...(moovers.data?.department_count
        .filter((d) => !idf?.includes(d.department))
        .map((dep) => ({
          name: dep.department.toString(),
          selector: () => dep.count.toString(),
        })) ?? []),
      {
        name: "Province",
        selector: () => moovers.data?.total_idf.toString() ?? "",
      },
      {
        name: "France ",
        selector: () => moovers.data?.total.toString() ?? "",
      },
    ],
    [moovers.data]
  );

  if (moovers.isLoading || moovers.data === undefined) {
    return (
      <BaseContainer>
        <Typography variant="h3" component="h1">
          Requêtes connexion moovers
        </Typography>

        <Box mt={3}>
          <DateRangePicker value={dateRange} onChange={setDateRange} />
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress />
        </Box>
      </BaseContainer>
    );
  }

  return (
    <BaseContainer>
      <Typography variant="h3" component="h1">
        Requêtes connexion moovers
      </Typography>

      <Box mt={3}>
        <DateRangePicker value={dateRange} onChange={setDateRange} />
      </Box>

      <Box mt={4}>
        <Typography variant="h4">Genre</Typography>
        <Box mt={1} />

        <TableContainer component={Paper} style={{ width: "30%" }}>
          <Table aria-label="gender">
            <TableHead>
              <TableRow>
                <TableCell align="center">Homme</TableCell>
                <TableCell align="center">Femme</TableCell>
                <TableCell align="center">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow /* style={{ '&:last-child td, &:last-child th': { border: 0 } }} */
              >
                <TableCell align="center">{moovers.data.gender.male}</TableCell>
                <TableCell align="center">
                  {moovers.data.gender.female}
                </TableCell>
                <TableCell align="center">{moovers.data.total}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={4}>
        <Typography variant="h4">Tranche d'âge</Typography>
        <Box mt={1} />

        <TableContainer component={Paper} style={{ width: "80%" }}>
          <Table aria-label="age-bracket">
            <TableHead>
              <TableRow>
                <TableCell align="center">&lt;17</TableCell>
                <TableCell align="center">17-19</TableCell>
                <TableCell align="center">20-21</TableCell>
                <TableCell align="center">22</TableCell>
                <TableCell align="center">23-24</TableCell>
                <TableCell align="center">25-26</TableCell>
                <TableCell align="center">&gt;27</TableCell>
                <TableCell align="center">total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow /* style={{ '&:last-child td, &:last-child th': { border: 0 } }} */
              >
                <TableCell align="center">
                  {moovers.data.age_bracket.lt_17}
                </TableCell>
                <TableCell align="center">
                  {moovers.data.age_bracket.bt_17_19}
                </TableCell>
                <TableCell align="center">
                  {moovers.data.age_bracket.bt_20_21}
                </TableCell>
                <TableCell align="center">
                  {moovers.data.age_bracket.bt_22}
                </TableCell>
                <TableCell align="center">
                  {moovers.data.age_bracket.bt_23_24}
                </TableCell>
                <TableCell align="center">
                  {moovers.data.age_bracket.bt_25_26}
                </TableCell>
                <TableCell align="center">
                  {moovers.data.age_bracket.gt_27}
                </TableCell>
                <TableCell align="center">{moovers.data.total}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={4}>
        <Typography variant="h4">Localisation</Typography>
        <Box mt={1} />

        <DataTable
          persistTableHead
          // noDataComponent
          columns={columns}
          data={[{ id: 1 } as any]}
          responsive
          noContextMenu
          noHeader
        />
      </Box>
    </BaseContainer>
  );
};
export default MooverScene;
