//@ts-nocheck
import {
  Box,
  BoxProps,
  Button,
  FormLabel,
  Paper,
  TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { api, snackbarCatch } from "../../services/api";
import FormSection from "../../components/form/FormSection";

type LoginForm = {
  username: string;
  password: string;
};

const LoginScene = () => {
  const { handleSubmit, control } = useForm<LoginForm>({
    mode: "onChange",
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: loginMutation } = api.auth.useLogin();
  const onSubmit = handleSubmit(async (form) => {
    try {
      await loginMutation({
        adminLoginUserDto: {
          username: form.username,
          password: form.password,
          ...form,
        },
      });
    } catch (e) {
      snackbarCatch(e, enqueueSnackbar);
    }
  });
  return (
    <Box pt={30}>
      <Box
        component={Paper}
        p={10}
        display="flex"
        flexDirection="column"
        m="0 auto"
        maxWidth="50em"
      >
        <form onSubmit={onSubmit}>
          <FormSection name="Username" htmlFor="username-textfield">
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  id="username-textfield"
                  {...field}
                />
              )}
            />
          </FormSection>
          <FormSection name="Password" htmlFor="password-textfield">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  id="password-textfield"
                  type="password"
                  {...field}
                />
              )}
            />
          </FormSection>
          <Box mt={3} display="flex" justifyContent="center">
            <Button type="submit" variant="outlined">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
export default LoginScene;
