//@ts-nocheck
import { Box, BoxProps, Button, FormLabel, Paper, TextField } from "@material-ui/core"
import { useSnackbar } from "notistack"
import React from "react"
import { useForm, Control, Controller, ControllerProps, FieldValues, Path } from "react-hook-form"
import { api, snackbarCatch } from "../../services/api"

type FormSectionProps = BoxProps & {
    name: string
    htmlFor?: string
    labelGap?: number
}
const FormSection = ({ mt = 3, labelGap, name, htmlFor, children, ...props }: FormSectionProps) => {
    return (
        <Box {...props} mt={mt}>
            <FormLabel htmlFor={htmlFor}>{name}</FormLabel>
            <Box mt={labelGap}>{children}</Box>
        </Box>
    )
}

export default FormSection
